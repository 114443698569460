import React from "react";
import { makeStyles } from "@material-ui/core";
import Quiz from "./components/Quiz";
import FeatureBlogs from "./components/FeatureBlogs";
import "react-multi-carousel/lib/styles.css";
import Footer from "../statics/Footer";
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 96,
  },
}));

const Home = React.forwardRef((props, ref) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Quiz {...props} />
      <FeatureBlogs {...props} />
      <Footer {...props} />
    </div>
  );
});

export default Home;
