import React from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import BlogDetailIMG1 from "../../assets/images/BlogDetailIMG1.svg";
import BlogDetailIMG2 from "../../assets/images/BlogDetailIMG2.svg";
import BlogDetailIMG3 from "../../assets/images/BlogDetailIMG3.svg";

import { ROCKWELL_REG, QUICKSAND_REG } from "../../GlobalConstants";
import Footer from "../statics/Footer";
import Blog1More from "./components/Blog1More";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 76,
    justifyContent: "flex-start",
    paddingLeft: "6%",
    [theme.breakpoints.down("sm")]: {
      marginTop: 61,
      justifyContent: "center",
      paddingLeft: "0",
    },
  },
  img: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },
  grid1: {
    [theme.breakpoints.up("md")]: { marginLeft: "15%" },
  },
  txt1Wrapper: {
    paddingTop: "4%",
    paddingBottom: "4%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "2%",
    },
  },
  txt1: {
    fontFamily: ROCKWELL_REG,
    color: theme.palette.primary.dark,
    fontSize: 30,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  txt1_1: {
    fontFamily: ROCKWELL_REG,
    color: theme.palette.primary.dark,
    fontSize: 22,
    textAlign: "left",
    maxWidth: "70%",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  txt2Wrapper: {
    //paddingBottom: "4%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "2%",
    },
  },
  txt2Wrapper1: {
    paddingTop: "3%",
    paddingBottom: "3%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "2%",
    },
  },
  txt2: {
    fontFamily: QUICKSAND_REG,
    color: theme.palette.primary.dark,
    fontSize: 16,
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
      maxWidth: "90%",
    },
  },
  txt3Wrapper: {
    paddingBottom: "4%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "2%",
      paddingTop: "3%",
    },
  },
  txt3: {
    fontFamily: QUICKSAND_REG,
    color: theme.palette.primary.dark,
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "left",
  },
  img2Wrapper: {
    paddingTop: "3%",
  },
  txt4Wrapper: {
    paddingTop: "2%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "2%",
    },
  },
  txt4: {
    fontFamily: ROCKWELL_REG,
    color: theme.palette.primary.dark,
    fontSize: 25,
    textAlign: "left",
  },
  ul: {
    color: theme.palette.primary.dark,
    padding: 0,
    margin: 0,
    paddingLeft: "3%",
  },
}));

const Blog1Detail = React.forwardRef((props, ref) => {
  const classes = useStyles();

  return (
    <div>
      <Grid container className={classes.root}>
        <Grid container className={classes.grid1} xs={12} md={5} item>
          <Grid item xs={12} className={classes.img1Wrapper}>
            <img alt="img1" src={BlogDetailIMG1} className={classes.img} />
          </Grid>
          <Grid xs={12} item container className={classes.txt1Wrapper}>
            <Typography className={classes.txt1}>
              5 Basic Commands To Train Your Dog
            </Typography>
            <Typography className={classes.txt1_1}>
              See how Adrienne cured Maggie’s jumping problem.{" "}
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Being a parent of a trained pooch isn’t like owning a stable dog.
              However, if your dog understands a few basic commands, then it can
              be useful for you during handling problems related to their
              behaviours.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Then how do you start with dog basic training? You could hire a
              trainer or attend some classes for dog training, but that’s not
              required; you can do all this by yourself. In fact, by using a
              suitable way, it can be enjoyable for both you and your dog!
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt3Wrapper}>
            <Typography className={classes.txt3}>
              So, here are these 5 commands which will help you to train your
              dog and he will be a trained pooch in just few days!
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.img2Wrapper}>
            <img alt="img2" src={BlogDetailIMG2} className={classes.img} />
          </Grid>
          <Grid xs={12} item container className={classes.txt4Wrapper}>
            <Typography className={classes.txt4}>Come</Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              This command can make you feel certain that your dog can be safe
              from any sort of danger. Taking him back to you if you miss the
              grip on the leash or unexpectedly leave the front door unlocked.
              behaviours.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <ul className={classes.ul}>
              <li>
                <Typography className={classes.txt2}>
                  Place a leash and collar on your dog. behaviours.
                </Typography>
              </li>
              <li>
                <Typography className={classes.txt2}>
                  Go down to his level and say, “Come,” while kindly tugging on
                  the leash.
                </Typography>
              </li>
              <li>
                <Typography className={classes.txt2}>
                  If he gets to you, reward him with a treat with some love.
                </Typography>
              </li>
            </ul>
          </Grid>

          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Once he understood the command with the leash, remove that and
              follow the command in a secured, confined space.
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.img2Wrapper}>
            <img alt="img3" src={BlogDetailIMG3} className={classes.img} />
          </Grid>
          <Grid xs={12} item container className={classes.txt4Wrapper}>
            <Typography className={classes.txt4}>Down</Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              This command is a bit difficult to teach amongst all other
              commands in this training. Why? Because the posture is a
              submissive one. You can guide by maintaining the whole training
              positive and comfortable, especially with nervous or fervent dogs.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Get a fresh smelling treat, and keep it in your folded fist.
            </Typography>
          </Grid>

          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <ul className={classes.ul}>
              <li>
                <Typography className={classes.txt2}>
                  Take your hand up to your dog’s nose. When he smells it, move
                  your palm to the ground, so he leads.
                </Typography>
              </li>
              <li>
                <Typography className={classes.txt2}>
                  Next, glide your hand towards the ground in front of him to
                  allow his body to follow his head.
                </Typography>
              </li>
              <li>
                <Typography className={classes.txt2}>
                  Once he’s in the down posture, say “Down,” give him the treat,
                  and show him some love.
                </Typography>
              </li>
            </ul>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Do this every day. If your pooch attempts to sit up or jumps
              toward your hand, say “No” and lift your hand away. Try not to
              drive him into a down position, and boost each progress your dog
              takes to the correct position. As we know, he is working hard for
              it!
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt4Wrapper}>
            <Typography className={classes.txt4}>Sit</Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              One of the simplest commands to train your dog is this one.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              You can begin with this step.
            </Typography>
          </Grid>

          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <ul className={classes.ul}>
              <li>
                <Typography className={classes.txt2}>
                  Keep a treat near your dog’s nose.
                </Typography>
              </li>
              <li>
                <Typography className={classes.txt2}>
                  Moving your hand upward, allowing his head to follow the treat
                  and making his bottom lower.
                </Typography>
              </li>
              <li>
                <Typography className={classes.txt2}>
                  When he’s in sitting position, say “Sit,” give him the treat,
                  and give him some love.
                </Typography>
              </li>
            </ul>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Do this step several times every day until your dog gets it
              completely. After this direct your dog to sit before every meal.
              Also remember, while going for walks and during other times also
              when you’d like him seated and be quiet.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt4Wrapper}>
            <Typography className={classes.txt4}>Stay</Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Before doing this step, ensure your dog is now expert at the “Sit”
              order.
            </Typography>
          </Grid>

          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <ul className={classes.ul}>
              <li>
                <Typography className={classes.txt2}>
                  To start with, ask that your dog “Sit.”
                </Typography>
              </li>
              <li>
                <Typography className={classes.txt2}>
                  At that point open the palm of your hand, and tell him to
                  “Stay.”
                </Typography>
              </li>
              <li>
                <Typography className={classes.txt2}>
                  Go a few steps back. Give him a treat and love if he sits.
                </Typography>
              </li>
              <li>
                <Typography className={classes.txt2}>
                  By the time increase the number of steps you take before
                  giving your dog the treat.
                </Typography>
              </li>
            </ul>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Continuously reward your pooch for staying even though he stays
              for only couple of minutes.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              This is an activity in balance for your dog, so don’t be
              disheartened if it requires some time to become an expert,
              especially for young doggies. As we know, it is important to move
              rather than staying at one place.
            </Typography>
          </Grid>

          {/** leave it */}
          <Grid xs={12} item container className={classes.txt4Wrapper}>
            <Typography className={classes.txt4}>Leave it</Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              This can help guard your pooch when his interest shows signs of
              improvement, as if he smells something delicious yet potentially
              dangerous on the ground. The main aim is to show your little pooch
              that he improves for ignoring the other thing.
            </Typography>
          </Grid>

          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <ul className={classes.ul}>
              <li>
                <Typography className={classes.txt2}>
                  Hold a treat in both hands.
                </Typography>
              </li>
              <li>
                <Typography className={classes.txt2}>
                  Give him one closed fist with the treat inside, and tell him
                  “Leave it.”
                </Typography>
              </li>
              <li>
                <Typography className={classes.txt2}>
                  Allow him to paw, mouth, sniff, lick, and bark to try and get
                  it — and ignore his actions.
                </Typography>
              </li>
              <li>
                <Typography className={classes.txt2}>
                  If at any point he stops trying, award him the treat from a
                  different hand.
                </Typography>
              </li>
              <li>
                <Typography className={classes.txt2}>
                  Repeat until your dog moves from that first hand when you say,
                  “Leave it.”
                </Typography>
              </li>
              <li>
                <Typography className={classes.txt2}>
                  Next, possibly give your pooch the treat when he moves from
                  that first hand and looks at you.
                </Typography>
              </li>
            </ul>
          </Grid>

          <Grid xs={12} item container className={classes.txt2Wrapper1}>
            <Typography className={classes.txt2}>
              When your dog probably moves from his first treat and you both
              have eye contact when you tell the command, you’re prepared to
              take it up an indent. For that, use some different treats, one
              that is only okay and one that is an especially nice smelling and
              delicious most loved for your little guy.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper1}>
            <Typography className={classes.txt2}>
              Say “Leave it,” place the less attractive treat on the ground, and
              hide it with your hand.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper1}>
            <Typography className={classes.txt2}>
              Hold up until your dog ignores that treat and look at you. At that
              point take away that treat from the ground, give him a more yummy
              treat and give him love.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper1}>
            <Typography className={classes.txt2}>
              When he has it, place the less delicious treat on the ground…
              however don’t cover it with your hand. Rather hold it a bit over
              the treat. After some time, steadily move your hand far away until
              your hand is around 6 inches above.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper1}>
            <Typography className={classes.txt2}>
              Soon he’s prepared to re-enact with you holding up! Follow these
              steps, yet if that he attempts to grab the less delicious treat,
              hide it with your foot.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper1}>
            <Typography className={classes.txt2}>
              Try not to rush the steps. Keep in mind, you’re soliciting a great
              deal from your pooch. If you take it up a notch and he’s battling,
              return to the earlier step.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper1}>
            <Typography className={classes.txt2}>
              Simply these five easy steps can help keep your dog more secure
              and grow your communication with him. It’s certainly useful
              regardless of the investment of your time and effort. Keep in
              mind, the steps require some time, so possibly start a dog
              training course only when you’re in the correct mindset to go with
              patience.
            </Typography>
          </Grid>
        </Grid>

        <Grid container className={classes.grid2}></Grid>

        <Grid container className={classes.grid3}></Grid>

        <Blog1More {...props} />
      </Grid>
      <Footer />
    </div>
  );
});

export default Blog1Detail;
