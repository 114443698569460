import React from "react";
import { makeStyles, Grid, Typography, Button } from "@material-ui/core";
import DOG from "../../../assets/images/dog4.svg";
import yesImg from "../../../assets/images/yesImg.svg";
import noImg from "../../../assets/images/noImg.svg";

import { QUICKSAND_REG, ROCKWELL_REG, INTER } from "../../../GlobalConstants";

const useStyles = makeStyles((theme) => ({
  question: {
    width: "60%",
    backgroundColor: theme.palette.primary.light,
    borderRadius: 20,
    paddingTop: "4%",
    paddingBottom: "4%",
    [theme.breakpoints.down("sm")]: {
      width: "72%",
      paddingTop: "10%",
      paddingBottom: "10%",
      minHeight: "80%",
    },
  },
  questionTxt1Wrapper: {
    alignContent: "center",
    paddingTop: "3%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  questionTxt2Wrapper: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  questionTxt3Wrapper: {
    marginBottom: "3%",
    paddingBottom: "4%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  questionTxt4Wrapper: {
    marginBottom: "3%",
    paddingBottom: "4%",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  questionTxt: {
    fontSize: 35,
    fontWeight: "bold",
    fontFamily: QUICKSAND_REG,
    color: theme.palette.primary.dark,
    [theme.breakpoints.down("sm")]: {
      fontSize: 22,
    },
  },
  yesBtnWrapper: {
    justifyContent: "flex-end",
    paddingRight: "2%",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      paddingRight: 0,
    },
  },
  yesBtn: {
    height: 60,
    width: 150,
    [theme.breakpoints.down("sm")]: {
      height: 50,
      width: 120,
    },
  },
  noBtnWrapper: {
    justifyContent: "flex-start",
    paddingLeft: "2%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "8%",
      justifyContent: "center",
      paddingLeft: "0%",
    },
  },
  noBtn: {
    height: 60,
    width: 150,
    [theme.breakpoints.down("sm")]: {
      height: 50,
      width: 120,
    },
  },
  btnText: {
    fontFamily: INTER,
    fontSize: 26,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  imgQuest: {
    // margingTop: "3%",
    [theme.breakpoints.down("sm")]: {
      width: 170,
      height: 100,
    },
  },
  yesBtnImg: {
    [theme.breakpoints.down("sm")]: {
      width: 17.04,
      height: 12.43,
    },
  },
  noBtnImg: {
    [theme.breakpoints.down("sm")]: {
      width: 14.64,
      height: 14.64,
    },
  },
}));

const Question4 = (props) => {
  const classes = useStyles();

  const yesHandler = () => {
    props.setCurrentQuest(1);
    let tempData = { ...props.data };
    let tempDataQuest = { ...tempData[4] };
    tempDataQuest.answer = "Yes";
    tempData[4] = tempDataQuest;
    props.setData(props.DEFAULT);

    props.history.push({
      pathname: "/emailSubmit",
      state: { data: tempData },
    });
  };

  const noHandler = () => {
    props.setCurrentQuest(1);
    let tempData = { ...props.data };
    let tempDataQuest = { ...tempData[4] };
    tempDataQuest.answer = "No";
    tempData[4] = tempDataQuest;
    props.setData(props.DEFAULT);

    props.history.push({
      pathname: "/emailSubmit",
      state: { data: tempData },
    });
  };

  return (
    <Grid className={classes.question} container justify="center">
      <Grid xs={12} item container justify="center">
        <img src={DOG} alt="pet" className={classes.imgQuest} />
      </Grid>
      <Grid xs={12} item container justify="center">
        <Grid
          xs={12}
          item
          container
          justify="center"
          className={classes.questionTxt4Wrapper}
        >
          <Typography className={classes.questionTxt}>
            Your dog has a fear of certain sounds or stimuli or suffers from
            anxiety?
          </Typography>
        </Grid>
        <Grid
          xs={12}
          item
          container
          justify="center"
          className={classes.questionTxt1Wrapper}
        >
          <Typography className={classes.questionTxt}>
            Your dog has a fear of certain
          </Typography>
        </Grid>
        <Grid
          xs={12}
          item
          container
          justify="center"
          className={classes.questionTxt2Wrapper}
        >
          <Typography className={classes.questionTxt}>
            sounds or stimuli or suffers
          </Typography>
        </Grid>
        <Grid
          xs={12}
          item
          container
          justify="center"
          className={classes.questionTxt3Wrapper}
        >
          <Typography className={classes.questionTxt}>from anxiety?</Typography>
        </Grid>
      </Grid>
      <Grid xs={12} md={6} item container className={classes.yesBtnWrapper}>
        <Button
          className={classes.yesBtn}
          onClick={yesHandler}
          endIcon={
            <img alt="tick" src={yesImg} className={classes.yesBtnImg} />
          }
        >
          <Typography className={classes.btnText}>Yes</Typography>
        </Button>
      </Grid>
      <Grid xs={12} md={6} item container className={classes.noBtnWrapper}>
        <Button
          onClick={noHandler}
          className={classes.noBtn}
          endIcon={<img alt="cross" src={noImg} className={classes.noBtnImg} />}
        >
          <Typography className={classes.btnText}>No</Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export default Question4;
