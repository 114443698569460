import React, { useState } from "react";
import {
  makeStyles,
  Grid,
  Typography,
  TextField,
  Button,
} from "@material-ui/core";
import { ROCKWELL_REG, QUICKSAND_REG, INTER } from "../../../GlobalConstants";
import BUTTON_ARROW from "../../../assets/images/BUTTON_ARROW.svg";
import { Redirect } from "react-router-dom";
import Firestore from "../../Firestore";

const _ = require("lodash");
const useStyles = makeStyles((theme) => ({
  question: {
    //height: "70%",
    width: "60%",
    backgroundColor: theme.palette.primary.light,
    borderRadius: 20,
    paddingTop: "5%",
    [theme.breakpoints.down("sm")]: {
      width: "72%",
      paddingTop: "5%",
      paddingBottom: "5%",
      height: "75%",
    },
  },
  txt1Wrapper: {
    //paddingBottom: "5%",
  },
  txt1: {
    fontSize: 56,
    fontFamily: ROCKWELL_REG,
    color: theme.palette.primary.main,
    marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      fontSize: 25,
    },
  },

  txt2Wrapper: {},
  txt2: {
    fontFamily: QUICKSAND_REG,
    fontSize: 22,
    fontWeight: "bold",
    color: theme.palette.primary.dark,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  txt2_1: {
    fontFamily: QUICKSAND_REG,
    fontSize: 16,
    fontWeight: "bold",
    maxWidth: "70%",
    color: theme.palette.primary.dark,
    lineHeight: 1.5,
    marginBottom: "5%",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  txt3Wrapper: {
    paddingBottom: "3%",
    marginTop: "-3%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  input1Wrapper: {
    marginBottom: "2%",
  },
  input2Wrapper: {
    marginBottom: "7%",
  },
  inputBox: {
    width: "60%",
    border: "none",
    marginBottom: "2%",

    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
  inputBox1: {
    width: "60%",
    border: "none",
    marginBottom: "2%",

    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
  btnWrapper: {
    paddingBottom: "4%",
  },
  btn: {
    backgroundColor: theme.palette.primary.main,
    width: "35%",
    height: 60,
    [theme.breakpoints.down("sm")]: {
      width: "70%",
      height: 40,
    },
  },
  btnTxt: {
    fontFamily: INTER,
    fontSize: 26,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  btnImg: {
    paddingLeft: "70%",
  },
}));

const Form = (props) => {
  const classes = useStyles();

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [query, setQuery] = useState();

  const submitHandler = async (e) => {
    e.preventDefault();
    const db = Firestore.firestore();
    db.settings({
      timestampsInSnapshots: true,
    });

    const formRef = db
      .collection("contact")
      .add({
        name: name,
        email: email,
        query: query,
        created: Firestore.firestore.Timestamp.now(),
      })
      .then((res) => {
        props.history.push("/contactSubmit");
      });
  };

  return (
    <Grid className={classes.question}>
      <Grid container justify="center">
        <Typography className={classes.txt1}>Got a query!</Typography>

        <Grid xs={12} item>
          <form onSubmit={submitHandler} style={{ width: "100%" }}>
            <TextField
              placeholder="Your Name"
              className={classes.inputBox}
              variant="outlined"
              required
              type="text"
              onChange={(e) => setName(e.target.value)}
            />

            <TextField
              placeholder="Your E-mail Address"
              className={classes.inputBox}
              variant="outlined"
              onChange={(e) => setEmail(e.target.value)}
              required
              type="email"
            />

            <TextField
              multiline
              rows={5}
              placeholder="Query"
              className={classes.inputBox1}
              variant="outlined"
              onChange={(e) => setQuery(e.target.value)}
              required
            />
            <Grid xs={12} item container justify="center">
              <Button
                className={classes.btn}
                type="submit"
                endIcon={
                  <Grid className={classes.btnImg} container justify="center">
                    <img alt="arrow" src={BUTTON_ARROW} />
                  </Grid>
                }
              >
                <Typography className={classes.btnTxt}>Contact </Typography>
              </Button>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Form;
