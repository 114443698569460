import React, { useState } from "react";
import {
  makeStyles,
  Grid,
  Typography,
  TextField,
  Button,
} from "@material-ui/core";
import { ROCKWELL_REG, QUICKSAND_REG, INTER } from "../../../GlobalConstants";
import GetAppIcon from "@material-ui/icons/GetApp";
import BUTTON_ARROW from "../../../assets/images/BUTTON_ARROW.svg";
import file from "../../../assets/images/The-Airplane-Game-by-Adrienne-Farricelli.pdf";

import { Redirect } from "react-router-dom";
import Firestore from "../../Firestore";

const _ = require("lodash");
const useStyles = makeStyles((theme) => ({
  question: {
    //height: "70%",
    width: "60%",
    backgroundColor: theme.palette.primary.light,
    borderRadius: 20,
    paddingTop: "4%",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      height: "75%",
    },
  },
  txt1Wrapper: {
    // paddingBottom: "5%",
  },
  txt1: {
    fontSize: 40,
    fontFamily: ROCKWELL_REG,
    color: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      fontSize: 19,
    },
  },

  txt2Wrapper: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 5,
      paddingRight: 5,
    },
  },
  txt2: {
    fontFamily: QUICKSAND_REG,
    fontSize: 22,
    fontWeight: "bold",
    maxWidth: "80%",
    color: theme.palette.primary.dark,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  txt2_1: {
    fontFamily: QUICKSAND_REG,
    fontSize: 16,
    fontWeight: "bold",
    minWidth: "100%",
    color: theme.palette.primary.dark,
    lineHeight: 1.5,
    marginBottom: "5%",
    textAlign: "left",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  btnWrapper: {
    marginTop: "4%",
    marginBottom: "4%",
    backgroundColor: theme.palette.primary.main,
    alignContent: "center",
  },
  btn: {
    backgroundColor: "#316086",
    width: "70%",
    height: 100,
    borderRadius: 0,
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      height: 60,
    },
  },
  btnTxt: {
    fontFamily: INTER,
    fontSize: 26,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      textAlign: "left",
    },
  },

  btnImg: {
    fontSize: 26,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  downloadIcon: {
    color: theme.palette.primary.light,
    fontSize: 175,
    [theme.breakpoints.down("sm")]: {
      fontSize: 50,
    },
  },
  btn1: {
    marginTop: "2%",
    paddingLeft: 25,
    paddingRight: 25,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 15,
      paddingRight: 15,
    },
  },
}));

const MainSection = (props) => {
  const classes = useStyles();

  const downloadFile = () => {
    var a = document.createElement("A");
    a.href = file;
    a.download = file.substr(file.lastIndexOf("/") + 1);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <Grid className={classes.question}>
      <Grid
        xs={12}
        item
        container
        justify="center"
        className={classes.txt1Wrapper}
      >
        <Typography className={classes.txt1}>
          Congratulations and Welcome
        </Typography>
      </Grid>
      <Grid
        xs={12}
        item
        container
        justify="center"
        className={classes.txt2Wrapper}
      >
        <Typography className={classes.txt2}>
          Download Your free customized Game to train your Dog.
        </Typography>
        <Typography className={classes.txt2_1}>
          Download Your free customized Game to train your Dog.
        </Typography>
      </Grid>

      <Grid
        xs={12}
        item
        container
        justify="center"
        className={classes.btnWrapper}
      >
        <Grid md={4} xs={12} item container justify="center">
          <GetAppIcon className={classes.downloadIcon} />
        </Grid>
        <Grid md={8} xs={12} item container justify="center">
          <Grid xs={12} container item justify="center" alignContent="center">
            <Button
              onClick={downloadFile}
              className={classes.btn}
              startIcon={<GetAppIcon className={classes.btnImg} />}
            >
              <Typography className={classes.btnTxt}>
                Click here to Download 150 Free PLANS and Guides!
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        xs={12}
        item
        container
        justify="center"
        className={classes.txt1Wrapper}
      >
        <Typography className={classes.txt1}>
          But wait !!! There's more !!!
        </Typography>
      </Grid>
      <Grid
        xs={12}
        item
        container
        justify="center"
        className={classes.txt2Wrapper}
      >
        <Typography className={classes.txt2}>
          Get a proven Battlefield Tested system for creating an incredibly well
          behaved, intelligent dog who follows your every command.
        </Typography>
        <Typography className={classes.txt2_1}>
          Get a proven Battlefield Tested system for creating an incredibly well
          behaved, intelligent dog who follows your every command.
        </Typography>
      </Grid>
      <Button className={classes.btn1}>
        <Typography className={classes.btnTxt1}>Get Now</Typography>
      </Button>
    </Grid>
  );
};

export default MainSection;
