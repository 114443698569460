import React from "react";
import "./App.css";
import Header from "./containers/statics/Header";
import { withRouter, Route, Switch } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import Home from "./containers/Home/Home";
import Questions from "./containers/Questions/Questions";
import EmailSubmit from "./containers/EmailSubmit/EmailSubmit";
import Blogs from "./containers/Blogs/Blogs";
import Blog1Detail from "./containers/BlogDetails/Blog1Detail";
import Blog2Detail from "./containers/BlogDetails/Blog2Detail";
import Blog3Detail from "./containers/BlogDetails/Blog3Detail";
import Blog4Detail from "./containers/BlogDetails/Blog4Detail";
import Blog5Detail from "./containers/BlogDetails/Blog5Detail";
import Blog6Detail from "./containers/BlogDetails/Blog6Detail";
import Blog7Detail from "./containers/BlogDetails/Blog7Detail";
import Blog8Detail from "./containers/BlogDetails/Blog8Detail";
import Blog9Detail from "./containers/BlogDetails/Blog9Detail";
import Blog10Detail from "./containers/BlogDetails/Blog10Detail";
import ConatctUs from "./containers/ContactUs/ContactUs";
import PrivacyPolicy from "./containers/statics/PrivacyPolicy";
import TnC from "./containers/statics/tnc";
import FormSubmit from "./containers/FormSubmit/FormSubmit";
import Congratulations from "./containers/Congratulations/Congratulations";
import ContactSubmit from "./containers/ContactSubmit/ContactSubmit";
import Video1 from "./containers/videos/Video1";
import Video2 from "./containers/videos/Video2";
import Video3 from "./containers/videos/Video3";

function App() {
  return (
    <div className="App">
      <ScrollToTop>
        <Header />
        <Switch>
          <Route path="/questions" exact component={Questions} />
          <Route path="/emailSubmit" exact component={EmailSubmit} />
          <Route path="/formSubmit" exact component={FormSubmit} />
          <Route path="/contactSubmit" exact component={ContactSubmit} />
          <Route path="/video1" exact component={Video1} />
          <Route path="/video2" exact component={Video2} />
          <Route path="/video3" exact component={Video3} />
          <Route path="/congratulations" exact component={Congratulations} />

          <Route path="/blogs" exact component={Blogs} />
          <Route path="/contact" exact component={ConatctUs} />
          <Route path="/privPolicy" exact component={PrivacyPolicy} />
          <Route path="/tnc" exact component={TnC} />

          <Route path="/blogDetail1" exact component={Blog1Detail} />
          <Route path="/blogDetail2" exact component={Blog2Detail} />
          <Route path="/blogDetail3" exact component={Blog3Detail} />
          <Route path="/blogDetail4" exact component={Blog4Detail} />
          <Route path="/blogDetail5" exact component={Blog5Detail} />
          <Route path="/blogDetail6" exact component={Blog6Detail} />
          <Route path="/blogDetail7" exact component={Blog7Detail} />
          <Route path="/blogDetail8" exact component={Blog8Detail} />
          <Route path="/blogDetail9" exact component={Blog9Detail} />
          <Route path="/blogDetail10" exact component={Blog10Detail} />

          <Route path="/" component={Home} />
        </Switch>
      </ScrollToTop>
    </div>
  );
}

export default withRouter(App);
