import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyDRoPJxmOGm6egsWgtNxYw-meBj46mKWNs",
  authDomain: "diy01-99a5d.firebaseapp.com",
  databaseURL: "https://diy01-99a5d.firebaseio.com",
  projectId: "diy01-99a5d",
  storageBucket: "diy01-99a5d.appspot.com",
  messagingSenderId: "727734143500",
  appId: "1:727734143500:web:55b564a65578b2e2cd7c29",
  measurementId: "G-R5M8TDHVZ8",
};

firebase.initializeApp(firebaseConfig);
export default firebase;
