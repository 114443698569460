import React from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import { ROCKWELL_REG } from "../../../GlobalConstants";
import PopularBlog1 from "./PopularBlogs/PopularBlog1";
import PopularBlog2 from "./PopularBlogs/PopularBlog2";
import PopularBlog3 from "./PopularBlogs/PopularBlog3";
import PopularBlog4 from "./PopularBlogs/PopularBlog4";
import PopularBlog5 from "./PopularBlogs/PopularBlog5";
import PopularBlog6 from "./PopularBlogs/PopularBlog6";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "5%",
    paddingLeft: "7%",
    paddingRight: "8%",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "10%",

      paddingLeft: "5%",
      paddingRight: "4%",
      paddingBottom: "20%",
    },
  },
  headerTxtWrapper: {
    paddingBottom: "5%",
    borderBottomColor: "#d1d1d1",
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
  },
  headerTxt: {
    fontFamily: ROCKWELL_REG,
    fontSize: 30,
    color: theme.palette.primary.dark,
  },
}));

const PopularBlogs = (props) => {
  const classes = useStyles();

  return (
    <Grid xs={12} md={5} item className={classes.root}>
      <Grid xs={12} item container className={classes.headerTxtWrapper}>
        <Typography className={classes.headerTxt}>Popular Blogposts</Typography>
      </Grid>
      <PopularBlog1 {...props} />
      <PopularBlog2 {...props} />
      <PopularBlog3 {...props} />
      <PopularBlog4 {...props} />
      <PopularBlog5 {...props} />
      <PopularBlog6 {...props} />
    </Grid>
  );
};

export default PopularBlogs;
