import React from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import { QUICKSAND_REG, ROCKWELL_REG } from "../../GlobalConstants";
import Blog1 from "./Components/Blog1";
import Footer from "../statics/Footer";
import PopularBlogs from "./Components/PopularBlogs";
import Blog3 from "./Components/Blog3";
import Blog4 from "./Components/Blog4";
import Blog5 from "./Components/Blog5";
import Blog6 from "./Components/Blog6";
import Blog2 from "./Components/Blog2";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 76,
    paddingLeft: "15%",
    paddingRight: "7",
    paddingBottom: "14%",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      marginTop: 60,
    },
  },
  bannerWrapper: {
    backgroundColor: theme.palette.primary.main,
    /*  width: 625,
    height: 254, */
    padding: "15%",
    paddingLeft: "3%",
    paddingRight: "3%",
  },
  bannerTxt: {
    textDecoration: "none",
    fontSize: 47,
    fontFamily: QUICKSAND_REG,
    color: theme.palette.primary.light,
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
      marginLeft: "23%",
      marginRight: "23%",
      fontSize: 34,
    },
  },
  txt2Wrapper: {
    paddingTop: "2%",
    paddingBottom: "2%",
    borderBottomColor: "#d1d1d1",
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "5%",
      marginRight: "6%",
      paddingTop: "10%",
    },
  },
  txt2: {
    fontSize: 30,
    fontFamily: ROCKWELL_REG,
    color: theme.palette.primary.dark,
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
  },
}));

const Blogs = React.forwardRef((props, ref) => {
  const classes = useStyles();

  return (
    <div>
      <Grid className={classes.root} container>
        <Grid xs={12} md={6} item container item>
          <Grid
            className={classes.bannerWrapper}
            justify="center"
            alignContent="center"
            xs={12}
            item
            container
          >
            <a
              className={classes.bannerTxt}
              target="_blank"
              href="https://8826ecpqwkvr2nez22sd36sb2x.hop.clickbank.net/?tid=FF"
            >
              Teach your dog to PLAY PIANO
            </a>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>Recent Blogposts</Typography>
          </Grid>
          <Blog1 {...props} />
          <Blog2 {...props} />
          <Blog3 {...props} />
          <Blog4 {...props} />
          <Blog5 {...props} />
          <Blog6 {...props} />
        </Grid>
        <PopularBlogs />
      </Grid>
      <Footer />
    </div>
  );
});

export default Blogs;
