import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { rootReducer as reducers } from "./rootReducer";
import { applyMiddleware, createStore } from "redux";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import ReduxThunk from "redux-thunk";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { ROCKWELL_REG, QUICKSAND_REG } from "./GlobalConstants";

const store = reducers && createStore(reducers, applyMiddleware(ReduxThunk));

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#ffffff",
      main: "#FF8080",
      dark: "#615A5A",
      error: "#f44336",
      success: "#4caf50",
    },
    secondary: {
      main: "#ffffff",
      light: "#E0E0E0",
      dark: "#373739",
      error: "#f44336",
      success: "#4caf50",
    },
    text: {
      primary: "#615A5A",
      secondary: "#B2B0B0",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
      divider: "rgba(0, 0, 0, 0.12)",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 500,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        color: "#ffffff",
        borderRadius: 35,
        backgroundColor: "#FF8080",
        textTransform: "none",
        //lineHeight: 1.4,
        "&:hover": {
          backgroundColor: "#8c8c8c",
        },
      },
      text: {
        paddingRight: "2%",
        paddingLeft: "2%",
        fontFamily: QUICKSAND_REG,
        fontStyle: "normal",
      },
    },
    MuiInput: {
      input: {
        "&::placeholder": {
          color: "#615A5A",
          fontFamily: QUICKSAND_REG,
          fontSize: 20,
          fontWeight: "bold",
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0,
      },
      notchedOutline: {
        borderWidth: 0,
      },
    },
    MuiInputBase: {
      root: {
        backgroundColor: "#F8F8F8",
        color: "#615A5A",
        fontFamily: QUICKSAND_REG,
        //fontSize: 20,
        fontWeight: "bold",
        //height: 50,
      },
      multiline: {
        //height: 150,
      },
    },
    MuiInputLabel: {
      root: {
        fontFamily: ROCKWELL_REG,
        "&$focused": {
          padding: "2%",
        },
      },
    },

    MuiFormLabel: {
      root: {
        color: "#707070",
        backgroundColor: "#ffffff",
        fontSize: 20,
        fontFamily: ROCKWELL_REG,

        "&$focused": {
          color: "#1D1D1F",
          borderWidth: 0,
        },
      },
    },
    MuiSelect: {
      root: {
        //color: "#1D1D1F",
        fontFamily: ROCKWELL_REG,
        fontWeight: "bold",
        textAlign: "left",
        paddingLeft: "3%",
      },

      icon: {
        color: "#1D1D1F",
      },
      selectMenu: {
        color: "#1D1D1F",
      },
    },
    MuiMenuItem: {
      root: {
        fontFamily: ROCKWELL_REG,
        color: "#1D1D1F",
        "&:hover": {
          backgroundColor: "#1D1D1F",
          color: "#ffffff",
        },
      },
    },
    MuiTypography: {
      body1: {
        //lineHeight: 1.4,
        fontFamily: ROCKWELL_REG,
      },
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
