import React, { useState } from "react";
import {
  makeStyles,
  Grid,
  Typography,
  TextField,
  Button,
} from "@material-ui/core";
import { QUICKSAND_REG } from "../../GlobalConstants";
import Footer from "./Footer";
const _ = require("lodash");
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 120,
    [theme.breakpoints.down("sm")]: {
      marginTop: 100,
    },
  },
  root1: {
    paddingBottom: "10%",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "10%",
    },
  },
  text1: {
    width: "100%",
    fontFamily: QUICKSAND_REG,
    fontSize: 22,
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  text2: {
    width: "100%",
    fontFamily: QUICKSAND_REG,
    fontSize: 40,
    fontWeight: "bold",
    textAlign: "left",
    marginBottom: "10%",
    [theme.breakpoints.down("sm")]: {
      fontSize: 22,
    },
  },
}));

const PrivacyPolicy = (props) => {
  const classes = useStyles();

  return (
    <Grid className={classes.root} container justify="center">
      <Grid
        xs={10}
        md={8}
        item
        container
        justify="center"
        className={classes.root1}
      >
        <Typography className={classes.text2}>Privacy Policy </Typography>

        <Typography className={classes.text1}>
          (’I’ and ‘we’ refers to the owner of this website,
          'www.diypetstore.com') I am committed to keeping all your personal
          details safe and secure. My privacy policy strictly protects the
          security of your personal information and honors your choices for its
          intended use. We carefully protect your data from loss, misuse,
          unauthorized access or disclosure, alteration, or destruction.
          Collecting Your Personal Information.
        </Typography>
        <Typography className={classes.text1}>
          I am committed to protecting your privacy. I will only use the
          information that I collect about you lawfully. I will not pass on your
          details to any third party and do not send out marketing e-mails to
          customers unless they have registered to receive such material,
          purchased from me, or downloaded one of my free or paid products. The
          type of information I ‘may’ collect about you includes:
        </Typography>
        <Typography className={classes.text1}>Your full name</Typography>
        <Typography className={classes.text1}>Your e-mail address</Typography>
        <Typography className={classes.text1}>Your postal address</Typography>
        <Typography className={classes.text1}>
          Generally, this information is requested when you are either
          downloading free products, when you purchase any of my products
          (eBooks, software etc.), or when you subscribe to my newsletters. I
          will never collect sensitive information about you without your
          explicit consent. The information I hold will be accurate and up to
          date. You can check the information that I hold about you by e-mailing
          us. If you find any inaccuracies we will delete or correct it
          promptly.
        </Typography>
        <Typography className={classes.text1}>
          How I Control Your Personal Information I will not use your personal
          information for any other reason other than to contact you. We do not
          sell, give away or rent your email address to any third party. If in
          the future I did for any reason wish to rent, sell or use your email
          address for any other purpose we will ask you for your permission
          first. How We Use Your Personal Information Your personal information
          is used for four main purposes and they are as follows:
        </Typography>
        <Typography className={classes.text1}>
          To send out information, recommendations and resources to you. To
          provide assistance to you after you have purchased products from me.
          To help me create and deliver content most relevant to you. To
          enlighten you about new products, services, tool, upgrades, special
          offers and information which I feel may be of use to you.
        </Typography>
        <Typography className={classes.text1}>
          I will only disclose your personal information, without notice, if
          required to do so by law or in the good faith belief that such action
          is necessary to: Conform to the edicts of the law or comply with legal
          process served on www.diypetstore.com;
        </Typography>
        <Typography className={classes.text1}>
          Protect and defend the rights or property of www.diypetstore.com Act
          in urgent circumstances to protect the personal safety of users of
          www.diypetstore.com.
        </Typography>
        <Typography className={classes.text1}></Typography>
        <Typography className={classes.text1}></Typography>
      </Grid>
      <Footer />
    </Grid>
  );
};

export default PrivacyPolicy;
