import React from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import { ROCKWELL_REG, QUICKSAND_REG } from "../../../GlobalConstants";
import More10 from "./MoreOptions/More10";
import More2 from "./MoreOptions/More2";
import More3 from "./MoreOptions/More3";
import More4 from "./MoreOptions/More4";
import More5 from "./MoreOptions/More5";
import More6 from "./MoreOptions/More6";

import More9 from "./MoreOptions/More9";
import More8 from "./MoreOptions/More8";
import More7 from "./MoreOptions/More7";
import More1 from "./MoreOptions/More1";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "5%",
    paddingBottom: "10%",

    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "20%",
    },
  },
  headerWrapper: {
    justifyContent: "center",
    borderBottomColor: "#d1d1d1",
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    paddingBottom: "2%",
  },
  headerTxt: {
    fontSize: 30,
    fontFamily: ROCKWELL_REG,
    color: theme.palette.primary.dark,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  headerTxt1: {
    fontSize: 22,
    //maxWidth: "89%",
    fontFamily: ROCKWELL_REG,
    color: theme.palette.primary.dark,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  popularWrapper: {
    justifyContent: "center",
    paddingTop: "5%",
  },
  popularWrapper1: {
    justifyContent: "center",
    paddingTop: "5%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const Blog9More = (props) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Grid
        md={5}
        xs={7}
        item
        container
        justify="center"
        className={classes.headerWrapper}
      >
        <Typography className={classes.headerTxt}>
          More From DIY PET STORE
        </Typography>
        <Typography className={classes.headerTxt1}>More From</Typography>
        <Typography className={classes.headerTxt1}>DIY PET STORE</Typography>
      </Grid>
      <Grid xs={8} item container className={classes.popularWrapper}>
        <More1 {...props} />
        <More2 {...props} />
        <More3 {...props} />
      </Grid>
      <Grid xs={8} item container className={classes.popularWrapper1}>
        <More4 {...props} />

        <More5 {...props} />
        <More6 {...props} />
      </Grid>
      <Grid xs={8} item container className={classes.popularWrapper1}>
        <More7 {...props} />

        <More8 {...props} />
        <More10 {...props} />
      </Grid>
    </Grid>
  );
};

export default Blog9More;
