import React from "react";
import { makeStyles, Grid, Typography, Button } from "@material-ui/core";
import DOG from "../../../assets/images/dog3.svg";
import yesImg from "../../../assets/images/yesImg.svg";
import noImg from "../../../assets/images/noImg.svg";

import { QUICKSAND_REG, ROCKWELL_REG, INTER } from "../../../GlobalConstants";

const useStyles = makeStyles((theme) => ({
  question: {
    width: "60%",
    backgroundColor: theme.palette.primary.light,
    borderRadius: 20,
    paddingTop: "3%",
    paddingBottom: "2%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      paddingTop: "10%",
      paddingBottom: "10%",
      height: "80%",
    },
  },
  questionTxt1Wrapper: {
    alignContent: "center",
    paddingTop: "3%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  questionTxt2Wrapper: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  questionTxt3Wrapper: {
    marginBottom: "3%",
    paddingBottom: "4%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  questionTxt4Wrapper: {
    marginBottom: "3%",
    paddingBottom: "4%",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  questionTxt: {
    fontSize: 35,
    fontWeight: "bold",
    fontFamily: QUICKSAND_REG,
    color: theme.palette.primary.dark,
    [theme.breakpoints.down("sm")]: {
      fontSize: 22,
    },
  },
  yesBtnWrapper: {
    justifyContent: "flex-end",
    paddingRight: "2%",
    [theme.breakpoints.down("sm")]: {
      //justifyContent: "center",
      paddingRight: 0,
    },
  },
  yesBtn: {
    height: 60,
    width: 150,
    [theme.breakpoints.down("sm")]: {
      height: 50,
      width: 120,
    },
  },
  noBtnWrapper: {
    justifyContent: "flex-start",
    paddingLeft: "2%",
    [theme.breakpoints.down("sm")]: {
      // marginTop: "8%",
      // justifyContent: "center",
      paddingLeft: "1%",
    },
  },
  noBtn: {
    height: 60,
    width: 150,
    [theme.breakpoints.down("sm")]: {
      height: 50,
      width: 120,
    },
  },
  btnText: {
    fontFamily: INTER,
    fontSize: 26,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  imgQuest: {
    // margingTop: "3%",
    [theme.breakpoints.down("sm")]: {
      width: 170,
      height: 100,
    },
  },
  yesBtnImg: {
    [theme.breakpoints.down("sm")]: {
      width: 17.04,
      height: 12.43,
    },
  },
  noBtnImg: {
    [theme.breakpoints.down("sm")]: {
      width: 10.64,
      height: 10.64,
    },
  },
}));

const Question3 = (props) => {
  const classes = useStyles();

  const yesHandler = () => {
    props.setCurrentQuest(props.currentQuest + 1);
    props.setData((prevData) => {
      let tempData = { ...prevData };
      let tempDataQuest = { ...tempData[3] };
      tempDataQuest.answer = "Yes";
      tempData[3] = tempDataQuest;
      return tempData;
    });
  };

  const noHandler = () => {
    props.setCurrentQuest(props.currentQuest + 1);
    props.setData((prevData) => {
      let tempData = { ...prevData };
      let tempDataQuest = { ...tempData[3] };
      tempDataQuest.answer = "No";
      tempData[3] = tempDataQuest;
      return tempData;
    });
  };

  return (
    <Grid className={classes.question} container justify="center">
      <Grid xs={12} item container justify="center">
        <img src={DOG} alt="pet" className={classes.imgQuest} />
      </Grid>
      <Grid xs={12} item container justify="center">
        <Grid
          xs={12}
          item
          container
          justify="center"
          className={classes.questionTxt4Wrapper}
        >
          <Typography className={classes.questionTxt}>
            Are you worried you might have to give up your dog because of
            behaviour problems you can’t handle?
          </Typography>
        </Grid>
        <Grid
          xs={12}
          item
          container
          justify="center"
          className={classes.questionTxt1Wrapper}
        >
          <Typography className={classes.questionTxt}>
            Are you worried you might have to
          </Typography>
        </Grid>
        <Grid
          xs={12}
          item
          container
          justify="center"
          className={classes.questionTxt2Wrapper}
        >
          <Typography className={classes.questionTxt}>
            give up your dog because of behaviour
          </Typography>
        </Grid>
        <Grid
          xs={12}
          item
          container
          justify="center"
          className={classes.questionTxt3Wrapper}
        >
          <Typography className={classes.questionTxt}>
            problems you can’t handle?
          </Typography>
        </Grid>
      </Grid>
      <Grid xs={6} md={6} item container className={classes.yesBtnWrapper}>
        <Button
          className={classes.yesBtn}
          onClick={yesHandler}
          endIcon={
            <img alt="tick" src={yesImg} className={classes.yesBtnImg} />
          }
        >
          <Typography className={classes.btnText}>Yes</Typography>
        </Button>
      </Grid>
      <Grid xs={6} md={6} item container className={classes.noBtnWrapper}>
        <Button
          onClick={noHandler}
          className={classes.noBtn}
          endIcon={<img alt="cross" src={noImg} className={classes.noBtnImg} />}
        >
          <Typography className={classes.btnText}>No</Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export default Question3;
