import React from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import BlogDetailIMG1 from "../../assets/images/BlogDetailIMG4.jpg";
import BlogDetailIMG2 from "../../assets/images/BlogDetailIMG2.svg";
import BlogDetailIMG3 from "../../assets/images/BlogDetailIMG3.svg";

import { ROCKWELL_REG, QUICKSAND_REG } from "../../GlobalConstants";
import Footer from "../statics/Footer";
import Blog4More from "./components/Blog4More";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 76,
    justifyContent: "flex-start",
    paddingLeft: "6%",
    [theme.breakpoints.down("sm")]: {
      marginTop: 61,
      justifyContent: "center",
      paddingLeft: "0",
    },
  },
  img: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },
  grid1: {
    [theme.breakpoints.up("md")]: { marginLeft: "15%" },
  },
  txt1Wrapper: {
    paddingTop: "4%",
    paddingBottom: "4%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "2%",
    },
  },
  txt1: {
    fontFamily: ROCKWELL_REG,
    color: theme.palette.primary.dark,
    fontSize: 30,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  txt1_1: {
    fontFamily: ROCKWELL_REG,
    color: theme.palette.primary.dark,
    fontSize: 22,
    textAlign: "left",
    maxWidth: "70%",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  txt2Wrapper: {
    //paddingBottom: "4%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "2%",
    },
  },
  txt2Wrapper1: {
    paddingTop: "3%",
    paddingBottom: "3%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "2%",
    },
  },
  txt2: {
    fontFamily: QUICKSAND_REG,
    color: theme.palette.primary.dark,
    fontSize: 16,
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
      maxWidth: "90%",
    },
  },
  txt3Wrapper: {
    paddingBottom: "4%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "2%",
      paddingTop: "3%",
    },
  },
  txt3: {
    fontFamily: QUICKSAND_REG,
    color: theme.palette.primary.dark,
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "left",
  },
  img2Wrapper: {
    paddingTop: "3%",
  },
  txt4Wrapper: {
    paddingTop: "2%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "2%",
    },
  },
  txt4: {
    fontFamily: ROCKWELL_REG,
    color: theme.palette.primary.dark,
    fontSize: 25,
    textAlign: "left",
  },
  ul: {
    color: theme.palette.primary.dark,
    padding: 0,
    margin: 0,
    paddingLeft: "3%",
  },
}));

const Blog4Detail = React.forwardRef((props, ref) => {
  const classes = useStyles();

  return (
    <div>
      <Grid container className={classes.root} justify="center">
        <Grid container className={classes.grid1} xs={12} md={5} item>
          <Grid item xs={12} className={classes.img1Wrapper}>
            <img alt="img1" src={BlogDetailIMG1} className={classes.img} />
          </Grid>
          <Grid xs={12} item container className={classes.txt1Wrapper}>
            <Typography className={classes.txt1}>
              Make Your Neighbours Not To Be Afraid Of Your Dog
            </Typography>
            <Typography className={classes.txt1_1}>
              Make Your Neighbours Not To Be Afraid Of Your Dog
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Has this happened to you that your next-door-neighbour gets afraid
              of your dog whenever they visit you for anything? It can sometimes
              be so stressful to take a walk in your colony with your dog and
              you won't be able to attend any of your neighbours as they can't
              stand there because of your pooch. Also, during any supermarket
              visit, you might catch up with any bad experience. In many
              situations, it can lead to steps taken through a Homeowners
              Association, or even animal control.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Practicing a few steps to get your neighbour’s confidence is
              needed. A conversion in his/her behaviour will not occur
              overnight. However, few people are very patient if they believe
              that you understand their concerns and are putting efforts towards
              them.
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.img2Wrapper}>
            <img alt="img2" src={BlogDetailIMG2} className={classes.img} />
          </Grid>
          <Grid xs={12} item container className={classes.txt4Wrapper}>
            <Typography className={classes.txt4}>Ask</Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              The initial move is to know what specific habits scare your
              neighbour the most. In some events, it may be recognized, however,
              you might also discover that your dog is engaged in some actions
              you didn’t even know about like attacking the fence or growling at
              people from a window or behind the door.
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.img2Wrapper}>
            <img alt="img3" src={BlogDetailIMG3} className={classes.img} />
          </Grid>
          <Grid xs={12} item container className={classes.txt4Wrapper}>
            <Typography className={classes.txt4}>Be polite</Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Some people are totally afraid of dogs or don’t admire them. Even
              if your dog is well-behaved, just in case that he’s a dog may be
              sufficient to set them off. This might be difficult to grasp but
              think about something that terrifies you and imagine someone
              trying to make you like it.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt4Wrapper}>
            <Typography className={classes.txt4}>Take a step</Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Positively, you’re now acting on your dog’s issues, however, now
              you got to know which actions are the most trouble causing one.
              Now you can work on them respectively. Work with family members
              and other people who are OK with your dog to improve his
              behaviour, or book a trainer to work on your dog with you.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt4Wrapper}>
            <Typography className={classes.txt4}>Listen</Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Don’t become defensive. This is a get-to-know your dog mission,
              not a possibility for you to clarify or explain your dog’s
              behaviour to anyone. Rather, concentrate on training your dog as
              much as you can, and apologize for any inconvenience.
            </Typography>
          </Grid>

          <Grid xs={12} item container className={classes.txt4Wrapper}>
            <Typography className={classes.txt4}>Set up a gathering</Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Trying to know your dog sometimes can create a big difference,
              particularly if your dog does something in his actions on the
              street that he doesn’t do at your house. Meeting with more relaxed
              mind can help both your dog and neighbour to let their differenced
              go down when over and around in the neighbourhood.
            </Typography>
          </Grid>

          <Grid xs={12} item container className={classes.txt4Wrapper}>
            <Typography className={classes.txt4}>
              Set pooches playdates
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              If you feel that the difficulty is your dog’s reaction with other
              dogs, then allow him to meet other dogs on the indifferent
              territory. Also, if you can’t get your next-door-neighbour to
              conform to playdates with her dog, give some time in dog parks and
              different dog-friendly spaces. The more your dog is nearby other
              dogs, the more assuring he is to be comfortable when confronting
              them in your colony. Moreover, make sure to provide your dog with
              regular socialization exercise.{" "}
            </Typography>
          </Grid>

          <Grid xs={12} item container className={classes.txt4Wrapper}>
            <Typography className={classes.txt4}>
              Call the neighbour to approach
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              This is a move that you should simply take if you are 100% certain
              in your dog’s prosperous improvement. If you’re worried, your dog
              will choose up on that energy, making the behaviour to return,
              which will just make the condition more critical. Furthermore, be
              certain to provide your dog a pleasant long walk first. With less
              restrained spirit, your dog will be less aggressive, and even more
              certain to perform well.{" "}
            </Typography>
          </Grid>
        </Grid>

        <Grid container className={classes.grid2}></Grid>

        <Grid container className={classes.grid3}></Grid>

        <Blog4More {...props} />
      </Grid>
      <Footer />
    </div>
  );
});

export default Blog4Detail;
