import React from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import BlogCard1 from "./BlogCards/BlogCard1";
import BlogCard2 from "./BlogCards/BlogCard2";
import BlogCard3 from "./BlogCards/BlogCard3";
import BlogCard4 from "./BlogCards/BlogCard4";
import BlogCard5 from "./BlogCards/BlogCard5";
import BlogCard6 from "./BlogCards/BlogCard6";
import BlogCard7 from "./BlogCards/BlogCard7";
import BlogCard8 from "./BlogCards/BlogCard8";
import BlogCard9 from "./BlogCards/BlogCard9";
import BlogCard10 from "./BlogCards/BlogCard10";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { NavLink } from "react-router-dom";
import { ROCKWELL_REG } from "../../../GlobalConstants";
import CustomLeftArrow from "./CustomBottons/CustomLeftArrow";
import CustomRightArrow from "./CustomBottons/CustomRightArrow";
import CustomDot from "./CustomBottons/CustomDot";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    partialVisibilityGutter: 30,
  },
};
const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "10%",
    paddingBottom: "4%",
    background: "linear-gradient(#e6e6e6, #ffffff);",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "15",
      paddingBottom: "10%",
    },
  },
  root1: {
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "20%",
    },
  },
  tex1Wrapper: {},
  text1: {
    color: theme.palette.primary.dark,
    fontSize: 30,
    [theme.breakpoints.down("sm")]: {
      fontSize: 25,
    },
  },

  carouselWrapper: {
    paddingBottom: "1%",
    justifyContent: "center",
    overflow: "hidden",
    paddingLeft: "5%",

    [theme.breakpoints.up("md")]: {
      paddingLeft: "3%",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      overflow: "visible",
    },
  },
  Carousel: {
    overflow: "visible",
    [theme.breakpoints.up("md")]: {},
    [theme.breakpoints.down("sm")]: {
      overflow: "hidden",
    },
  },
  exploreWrapper: {
    paddingBottom: "6%",
    paddingLeft: "5%",
  },
  exploreLink: {
    color: theme.palette.text.secondary,
    fontFamily: ROCKWELL_REG,
    marginTop: "8%",
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  dot: {
    display: "none",
    height: 14,
    width: 14,
    borderRadius: "50%",
    backgroundColor: theme.palette.text.secondary,
    marginTop: "0.9%",
  },
}));

const FeatureBlogs = React.forwardRef((props, ref) => {
  const classes = useStyles();

  return (
    <Grid container justify="center" className={classes.root1}>
      <Grid container justify="center" className={classes.root}>
        <Grid className={classes.tex1Wrapper} container justify="center">
          <Typography className={classes.text1}>Featured Blog Posts</Typography>
        </Grid>
      </Grid>
      <Grid md={10} xs={10} className={classes.carouselWrapper} item>
        <Carousel
          additionalTransfrom={0}
          partialVisible={true}
          arrows
          responsive={responsive}
          showDots={true}
          swipeable
          //containerClass="container"
          className={classes.Carousel}
          customLeftArrow={<CustomLeftArrow />}
          customRightArrow={<CustomRightArrow />}
          customDot={<CustomDot />}
          focusOnSelect={true}
          renderButtonGroupOutside={true}
        >
          <BlogCard1 {...props} />
          <BlogCard2 {...props} />
          <BlogCard3 {...props} />
          <BlogCard4 {...props} />
          <BlogCard5 {...props} />
          <BlogCard6 {...props} />
          <BlogCard7 {...props} />
          <BlogCard8 {...props} />
          <BlogCard9 {...props} />
          <BlogCard10 {...props} />
        </Carousel>
      </Grid>
      <Grid
        xs={12}
        item
        container
        justify="center"
        className={classes.exploreWrapper}
        alignContent="center"
      >
        <Grid xs={1} container item justify="center">
          <NavLink to="/blogs" className={classes.exploreLink}>
            Explore Blogs
          </NavLink>
        </Grid>
        <Grid className={classes.dot} item container />
      </Grid>
    </Grid>
  );
});

export default FeatureBlogs;
