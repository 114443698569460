import * as React from "react";

const CustomDot = ({ onClick, active, index, carouselState }) => {
  const { currentSlide } = carouselState;
  return (
    /*  <li style={{ background: active ? "#FF8080" : "#FCD3D3" }}> */
    <button
      style={{
        background: active ? "#FF8080" : "#FCD3D3",
        borderRadius: 20,
        border: "none",
        height: 15,
        width: 15,
        marginTop: 10,
        marginRight: 5,
        outline: "none",
      }}
      onClick={() => onClick()}
    />
    /*     </li>
     */
  );
};

export default CustomDot;
