import React from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import BlogDetailIMG1 from "../../assets/images/BlogDetailIMG1.svg";
import BlogDetailIMG2 from "../../assets/images/BlogDetailIMG2.svg";
import BlogDetailIMG3 from "../../assets/images/BlogDetailIMG3.svg";

import { ROCKWELL_REG, QUICKSAND_REG } from "../../GlobalConstants";
import Footer from "../statics/Footer";
import Blog7More from "./components/Blog7More";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 76,
    justifyContent: "flex-start",
    paddingLeft: "6%",
    [theme.breakpoints.down("sm")]: {
      marginTop: 61,
      justifyContent: "center",
      paddingLeft: "0",
    },
  },
  img: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },
  grid1: {
    [theme.breakpoints.up("md")]: { marginLeft: "15%" },
  },
  txt1Wrapper: {
    paddingTop: "4%",
    paddingBottom: "4%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "2%",
    },
  },
  txt1: {
    fontFamily: ROCKWELL_REG,
    color: theme.palette.primary.dark,
    fontSize: 30,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  txt1_1: {
    fontFamily: ROCKWELL_REG,
    color: theme.palette.primary.dark,
    fontSize: 22,
    textAlign: "left",
    maxWidth: "70%",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  txt2Wrapper: {
    //paddingBottom: "4%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "2%",
    },
  },
  txt2Wrapper1: {
    paddingTop: "3%",
    paddingBottom: "3%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "2%",
    },
  },
  txt2: {
    fontFamily: QUICKSAND_REG,
    color: theme.palette.primary.dark,
    fontSize: 16,
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
      maxWidth: "90%",
    },
  },
  txt3Wrapper: {
    paddingBottom: "4%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "2%",
      paddingTop: "3%",
    },
  },
  txt3: {
    fontFamily: QUICKSAND_REG,
    color: theme.palette.primary.dark,
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "left",
  },
  img2Wrapper: {
    paddingTop: "3%",
  },
  txt4Wrapper: {
    paddingTop: "2%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "2%",
    },
  },
  txt4: {
    fontFamily: ROCKWELL_REG,
    color: theme.palette.primary.dark,
    fontSize: 25,
    textAlign: "left",
  },
  ul: {
    color: theme.palette.primary.dark,
    padding: 0,
    margin: 0,
    paddingLeft: "3%",
  },
}));

const Blog7Detail = React.forwardRef((props, ref) => {
  const classes = useStyles();

  return (
    <div>
      <Grid container className={classes.root} justify="center">
        <Grid container className={classes.grid1} xs={12} md={5} item>
          <Grid item xs={12} className={classes.img1Wrapper}>
            <img alt="img1" src={BlogDetailIMG1} className={classes.img} />
          </Grid>

          <Grid xs={12} item container className={classes.txt1Wrapper}>
            <Typography className={classes.txt1}>
              Importance Of Dental Care For Your Pet
            </Typography>
            <Typography className={classes.txt1_1}>
              Importance Of Dental Care For Your Pet
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Not only for humans - Dental care plays an important role in your
              pet's life also. This has to been taken care of. Like us, dogs
              have teeth that may cause problems if they are not taken care of
              with a regular dental habit. Some might have not seen this but
              dogs do suffer from bad breath just like us humans.
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.img2Wrapper}>
            <img alt="img2" src={BlogDetailIMG2} className={classes.img} />
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              The most important person during this part is your dog's vet. He
              will tell you the ways of how to brush your dog's teeth in a
              precise manner. They know the method to make your pet gums
              disease-free and also keep them safe from any other dental
              problems. Another important thing that you need to focus on is to
              find the correct toothbrush for your dog. Yes, you can use a
              child's toothbrush. Although, it is suggested to buy a special
              toothbrush for your dog's teeth. It will not only make the teeth
              brushing easy, but it is also worth your money.
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.img2Wrapper}>
            <img alt="img3" src={BlogDetailIMG3} className={classes.img} />
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              You can visit your vet or any local pet shop to buy the toothbrush
              for your dog. There are so many varieties of pet toothbrushes and
              toothpaste which are available at the store that might confuse
              you. In this situation, ask for help if you are not able to decide
              on it. Some rules have to be taken care of while buying a suitable
              toothbrush for your dog. Look for a toothbrush whose bristles are
              soft which will not damage your dog's gums. The most important
              thing is the size of the brush. It should not be too small or big
              for your dog's mouth. Also, make sure the handle of the toothbrush
              is giving you a strong grip and it is comfortable for you to hold
              it.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              It is also possible to use a human toothbrush on your dog. Despite
              using a toothbrush with high-quality ingredients particularly made
              for dogs. For that also you need to spend some time picking up
              your toothpaste too. Some cheap brands use ingredients that might
              be harmful to your dog which in any case can make your dog sick.
              Carefully read the ingredients that the toothpaste doesn't contain
              salt, enzymes, or any sort of detergent. There are plenty of
              options available at the pet store so in any situation you can ask
              for help and do read the label thoroughly.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              If your dog is not used to brushing, then it might get difficult
              for you for the first time. To avoid this situation, one must
              start the habit of teeth brushing when they are puppies. Even if
              it is too late for you then also don't worry. The solution is to
              be kind and gentle, speak to them in a soft voice, and do not try
              to force the dog. He should know that it is not a big thing. Your
              pet should not be scared of this. To begin with, make sure to
              start from the back of his mouth in circular motions.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              In a situation where your dog is not allowing you to brush his
              teeth then at first, you can use a towel. Start with trying
              rubbing his teeth with your finger so that he can get used to the
              impression.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Speak to him politely and gently. You can also sing a light song
              to soothe him down. You will notice that after a few regular
              weeks, your dog will allow you to brush his teeth without any
              troubles.{" "}
            </Typography>
          </Grid>
        </Grid>

        <Grid container className={classes.grid2}></Grid>

        <Grid container className={classes.grid3}></Grid>

        <Blog7More {...props} />
      </Grid>
      <Footer />
    </div>
  );
});

export default Blog7Detail;
