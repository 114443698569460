import React from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import BlogDetailIMG1 from "../../assets/images/BlogDetailIMG5.jpg";
import BlogDetailIMG2 from "../../assets/images/BlogDetailIMG2.svg";
import BlogDetailIMG3 from "../../assets/images/BlogDetailIMG3.svg";

import { ROCKWELL_REG, QUICKSAND_REG } from "../../GlobalConstants";
import Footer from "../statics/Footer";
import Blog5More from "./components/Blog5More";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 76,
    justifyContent: "flex-start",
    paddingLeft: "6%",
    [theme.breakpoints.down("sm")]: {
      marginTop: 61,
      justifyContent: "center",
      paddingLeft: "0",
    },
  },
  img: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },
  grid1: {
    [theme.breakpoints.up("md")]: { marginLeft: "15%" },
  },
  txt1Wrapper: {
    paddingTop: "4%",
    paddingBottom: "4%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "2%",
    },
  },
  txt1: {
    fontFamily: ROCKWELL_REG,
    color: theme.palette.primary.dark,
    fontSize: 30,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  txt1_1: {
    fontFamily: ROCKWELL_REG,
    color: theme.palette.primary.dark,
    fontSize: 22,
    textAlign: "left",
    maxWidth: "70%",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  txt2Wrapper: {
    //paddingBottom: "4%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "2%",
    },
  },
  txt2Wrapper1: {
    paddingTop: "3%",
    paddingBottom: "3%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "2%",
    },
  },
  txt2: {
    fontFamily: QUICKSAND_REG,
    color: theme.palette.primary.dark,
    fontSize: 16,
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
      maxWidth: "90%",
    },
  },
  txt3Wrapper: {
    paddingBottom: "4%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "2%",
      paddingTop: "3%",
    },
  },
  txt3: {
    fontFamily: QUICKSAND_REG,
    color: theme.palette.primary.dark,
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "left",
  },
  img2Wrapper: {
    paddingTop: "3%",
  },
  txt4Wrapper: {
    paddingTop: "2%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "2%",
    },
  },
  txt4: {
    fontFamily: ROCKWELL_REG,
    color: theme.palette.primary.dark,
    fontSize: 25,
    textAlign: "left",
  },
  ul: {
    color: theme.palette.primary.dark,
    padding: 0,
    margin: 0,
    paddingLeft: "3%",
  },
}));

const Blog5Detail = React.forwardRef((props, ref) => {
  const classes = useStyles();

  return (
    <div>
      <Grid container className={classes.root} justify="center">
        <Grid container className={classes.grid1} xs={12} md={5} item>
          <Grid item xs={12} className={classes.img1Wrapper}>
            <img alt="img1" src={BlogDetailIMG1} className={classes.img} />
          </Grid>
          <Grid xs={12} item container className={classes.txt1Wrapper}>
            <Typography className={classes.txt1}>
              Planning for Puppy’s First Bath? Here is the Guide For You
            </Typography>
            <Typography className={classes.txt1_1}>
              Planning for Puppy’s First Bath? Here is the Guide For You
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Puppies, just like kids, constantly drive to getting muddy fast as
              they explore the world around them. Their nonstop questioning will
              get them dirty, stinking, and glutinous a little too frequently,
              and finally, they will need their first shower.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              The first shower can be a stressful and baffling adventure for a
              puppy and it is a great approach to have a plan in place before
              you start. Do not hurry the practice as it might frighten your
              furry little one and make him/her hate a shower or grooming for
              the rest of his/her life.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt3Wrapper}>
            <Typography className={classes.txt3}>
              If you’re a little nervous about bathing your puppy for the first
              time, don’t worry you’re not alone. Here are a few steps to put
              your mind at ease and make this a calm and positive experience for
              you and your puppy.
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.img2Wrapper}>
            <img alt="img2" src={BlogDetailIMG2} className={classes.img} />
          </Grid>
          <Grid xs={12} item container className={classes.txt4Wrapper}>
            <Typography className={classes.txt4}>
              Building the bathing place
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              When you’re bathing a puppy it is important they feel safe, use a
              non-skid mat as a precaution to avoid your puppy and you from
              sliding throughout the bathing or when the bath is done. If you
              are using a bathtub, don't forget to insert a non-skid mat in the
              tub. Make sure that the temperature of the water is warm, not too
              hot. Also, the level of the water is half the height of your dog
              to avoid drowning.
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.img2Wrapper}>
            <img alt="img3" src={BlogDetailIMG3} className={classes.img} />
          </Grid>
          <Grid xs={12} item container className={classes.txt4Wrapper}>
            <Typography className={classes.txt4}>
              Making your puppy ready
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Many pups love to bathe and some don't. It also depends on how we
              introduce them to the water. Before the first bath of your puppy,
              try and play with him for a few minutes to cheer him up near the
              bathing area to familiarise him with the water space. If you are
              planning to take him to a groomer first, then make few visits to
              the salon before the initial bath. Your puppy should feel like
              it's his playtime and you must acknowledge him with some yummy
              treats and praise him for every good action he does.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              YES, it will take few bathes to get him used to it. But be sure
              that he is completely feeling comfortable before you introduce
              your pet to the water.{" "}
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              This is a very significant move and you should not just rush. Your
              puppy might take some time to adjust to this bathing process, you
              don't give up at anytime. Have patience and repeat this process
              until he get used to it.{" "}
            </Typography>
          </Grid>

          <Grid xs={12} item container className={classes.txt4Wrapper}>
            <Typography className={classes.txt4}>
              Introducing your puppy to the water
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              At the time when you feel that your puppy is adjusting to the
              surrounding of the water then introduce him to the sound of water
              by making him run for some time and then slowly splash some water
              n him to show him that there is nothing to be afraid of.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Get his reactions and after that if you observe that he is
              comfortable now then pour some water on him very gently. Give him
              multiple praises while pouring some water and don't try to rush
              your puppy if he seems to be afraid of water.
            </Typography>
          </Grid>

          <Grid xs={12} item container className={classes.txt4Wrapper}>
            <Typography className={classes.txt4}>
              Picking up the best shampoo
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Puppies have sensitive skin that can get quickly disturbed. When
              you’re washing your puppy, pick a mild oatmeal-based or natural
              (chemical-free) shampoo that is particularly created for dogs.
            </Typography>
          </Grid>

          <Grid xs={12} item container className={classes.txt4Wrapper}>
            <Typography className={classes.txt4}>
              Maintain the atmosphere positive around him
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Take out any sort of distraction near your puppy during the
              bathing. Collect all your supplies like shampoo, bucket, jug,
              towels etc before the bath. Also, carry few treats near you to
              help your puppy with keeping up the energy while taking the bath.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              During the bathing time, keep the atmosphere happy and relaxed so
              that your puppy feels uplifted and become happy. Keep yourself
              calm which will also make you puupy feel the same.{" "}
            </Typography>
          </Grid>

          <Grid xs={12} item container className={classes.txt4Wrapper}>
            <Typography className={classes.txt4}>
              Drying and brushing out afterwards
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Although dogs naturally lead to shake off extra water from them,
              you will have to support them dry off thoroughly with a towel.
              Keep your puppy warm and aside from the wind while the fur dries
              out completely.{" "}
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              For long hair dogs, whisk the fur out when the skin is dry as
              twists and knots will be more comfortable to exclude. Handling a
              dog comb, lightly comb out any covers or knots, assuring not to
              pull on the fur and causing discomfort. Be as soft but stable as
              attainable. Once finished, do not skip to shower him/her with so
              much of praise and treats for being a docile puppy.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              The process may take a little bit of time to get normal but don't
              give up anytime during this. Have patience as your puppy requires
              to get used to this. Always remember that the first time will be
              very important for your puppy so it is crucial to make it the best
              one and handle it gently and with full positivity.
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.grid2}></Grid>

        <Grid container className={classes.grid3}></Grid>

        <Blog5More {...props} />
      </Grid>
      <Footer />
    </div>
  );
});

export default Blog5Detail;
