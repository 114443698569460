import React from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import BlogDetailIMG1 from "../../assets/images/BlogDetailIMG1.svg";
import BlogDetailIMG2 from "../../assets/images/BlogDetailIMG2.svg";
import BlogDetailIMG3 from "../../assets/images/BlogDetailIMG3.svg";

import { ROCKWELL_REG, QUICKSAND_REG } from "../../GlobalConstants";
import Footer from "../statics/Footer";
import Blog9More from "./components/Blog9More";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 76,
    justifyContent: "flex-start",
    paddingLeft: "6%",
    [theme.breakpoints.down("sm")]: {
      marginTop: 61,
      justifyContent: "center",
      paddingLeft: "0",
    },
  },
  img: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },
  grid1: {
    [theme.breakpoints.up("md")]: { marginLeft: "15%" },
  },
  txt1Wrapper: {
    paddingTop: "4%",
    paddingBottom: "4%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "2%",
    },
  },
  txt1: {
    fontFamily: ROCKWELL_REG,
    color: theme.palette.primary.dark,
    fontSize: 30,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  txt1_1: {
    fontFamily: ROCKWELL_REG,
    color: theme.palette.primary.dark,
    fontSize: 22,
    textAlign: "left",
    maxWidth: "70%",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  txt2Wrapper: {
    //paddingBottom: "4%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "2%",
    },
  },
  txt2Wrapper1: {
    paddingTop: "3%",
    paddingBottom: "3%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "2%",
    },
  },
  txt2: {
    fontFamily: QUICKSAND_REG,
    color: theme.palette.primary.dark,
    fontSize: 16,
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
      maxWidth: "90%",
    },
  },
  txt3Wrapper: {
    paddingBottom: "4%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "2%",
      paddingTop: "3%",
    },
  },
  txt3: {
    fontFamily: QUICKSAND_REG,
    color: theme.palette.primary.dark,
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "left",
  },
  img2Wrapper: {
    paddingTop: "3%",
  },
  txt4Wrapper: {
    paddingTop: "2%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "2%",
    },
  },
  txt4: {
    fontFamily: ROCKWELL_REG,
    color: theme.palette.primary.dark,
    fontSize: 25,
    textAlign: "left",
  },
  ul: {
    color: theme.palette.primary.dark,
    padding: 0,
    margin: 0,
    paddingLeft: "3%",
  },
}));

const Blog9Detail = React.forwardRef((props, ref) => {
  const classes = useStyles();

  return (
    <div>
      <Grid container className={classes.root} justify="center">
        <Grid container className={classes.grid1} xs={12} md={5} item>
          <Grid item xs={12} className={classes.img1Wrapper}>
            <img alt="img1" src={BlogDetailIMG1} className={classes.img} />
          </Grid>
          <Grid xs={12} item container className={classes.txt1Wrapper}>
            <Typography className={classes.txt1}>
              Want To Know More About Pet Insurance?
            </Typography>
            <Typography className={classes.txt1_1}>
              Want To Know More About Pet Insurance?
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Few years back who would have thought that pet insurance will be a
              thing. Only people with luxury would be able to afford the cost of
              the insurance. But today, pet insurance has become very in and
              affordable. People are now talking about this. So, to help you out
              and give you a better understanding regarding pet insurance, we
              have written down some valuable insights which will clear all your
              queries.
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.img2Wrapper}>
            <img alt="img2" src={BlogDetailIMG2} className={classes.img} />
          </Grid>
          <Grid xs={12} item container className={classes.txt4Wrapper}>
            <Typography className={classes.txt4}>
              Pet insurance is not costly.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              So many people believe that pet insurance is more costly than
              human insurance but that is not exactly true. Pet insurances would
              vary from $15 to $48 per month, depending on what breed you have
              and also depending on what sort of plan you are drawn towards.
              This is far from what we would spend on emergencies like an
              unforeseen sickness or misfortune.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              More pet insurance companies are offering insurance coverage which
              are more affordable by giving discounts if you have more than one
              pet at your home. There are further discounts for two or three
              pets. Some companies provide you decent payment plans as well.{" "}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.img2Wrapper}>
            <img alt="img3" src={BlogDetailIMG3} className={classes.img} />
          </Grid>
          <Grid xs={12} item container className={classes.txt4Wrapper}>
            <Typography className={classes.txt4}>
              “Dogs and cats” are not the only one who can get insurance.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Contrary to what most people believe, dogs and cats are not the
              only animals that can attain the insurance benefits. Birds and
              exotic pets are also the one who can get insured. Exotic pet
              insurance could cover vet costs, pet crime, pet quarantine fees,
              accident, medication and passport fees. The charge of the coverage
              would depend on the notion that you’re considering.
            </Typography>
          </Grid>

          <Grid xs={12} item container className={classes.txt4Wrapper}>
            <Typography className={classes.txt4}>
              {` A pet at home all the time is safer & don’t require an insurance.`}
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Not literally. There are several contributing factors or causes
              why our pets are becoming ill. It could be that we neglect about
              their immunizations, we are serving them a food that has too many
              components in it which can ultimately lead to cancer, diabetes or
              any sort of diseases.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Moreover, if your pet just stays at home and you made sure that
              your house is pet-friendly, it does not imply your pets will be
              harmless from any sorts of mishaps and diseases which still makes
              a pet insurance required in guarding over your pet’s well-being.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              If you’ve ever been stuck in a position where one of your darling
              pets is ill and you do not have the money to spend on their
              health, it doesn’t leave you with so many options. Our pets are
              our family only and now that pet insurance is provided and easily
              affordable, it is so much more reassuring to know you are being
              covered if something unfortunate happen your little pooch.
            </Typography>
          </Grid>

          <Grid xs={12} item container className={classes.txt4Wrapper}>
            <Typography className={classes.txt4}>
              A pet fund is not so beneficial as pet insurance.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Many people believe that allocating funds for an emergency pet
              fund is similar to giving pet insurance. The purpose might be
              similar, both are for the well-being of our pets, but the
              difficulty with the emergency fund is that we are not so committed
              towards maintaining it in long run. There is no obligation, we can
              put saving for the pet fund away for the coming payday.
            </Typography>
          </Grid>

          <Grid xs={12} item container className={classes.txt4Wrapper}>
            <Typography className={classes.txt4}>
              Pet insurance is not similar to human insurance.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Although, we all know that there is a vast difference between
              these two but it’s still concerning. No one can force a pet to
              select a veterinarian from a list of covered providers. You can
              take your pet to any vet you wish to and the insurance will pay
              you back.
            </Typography>
          </Grid>

          <Grid xs={12} item container className={classes.txt4Wrapper}>
            <Typography className={classes.txt4}> Age Concerns</Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              It is also believed to wait until your pet gets older before you
              get insurance. We researched regarding this and we found that the
              age of a pet is not a concern. So, it is recommended to get your
              pet insurance as early as you can.{" "}
            </Typography>
          </Grid>

          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              However, few people would say that getting pet insurance for older
              pets is no longer qualified. But we believe that this depends on
              the insurance company. Few of the insurance companies would not
              consider pets that have pre-existing health ailments or who are
              comparatively older. To get the accurate report if a specific
              insurer would have any age specifications, it’s even more better
              to just get a free quote and get all the details according to your
              condition.
            </Typography>
          </Grid>
        </Grid>

        <Grid container className={classes.grid2}></Grid>

        <Grid container className={classes.grid3}></Grid>

        <Blog9More {...props} />
      </Grid>
      <Footer />
    </div>
  );
});

export default Blog9Detail;
