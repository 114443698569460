import React, { useState } from "react";
import {
  makeStyles,
  Grid,
  Typography,
  TextField,
  Button,
} from "@material-ui/core";
import { QUICKSAND_REG } from "../../GlobalConstants";
import Footer from "./Footer";
import { NavLink } from "react-router-dom";
const _ = require("lodash");
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 120,
    [theme.breakpoints.down("sm")]: {
      marginTop: 100,
    },
  },
  root1: {
    paddingBottom: "10%",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "10%",
    },
  },
  link: {
    textDecoration: "none",
    color: theme.palette.primary.main,
  },
  text1: {
    width: "100%",
    fontFamily: QUICKSAND_REG,
    fontSize: 22,
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  text2: {
    width: "100%",
    fontFamily: QUICKSAND_REG,
    fontSize: 40,
    fontWeight: "bold",
    textAlign: "left",
    marginBottom: "10%",
    [theme.breakpoints.down("sm")]: {
      fontSize: 22,
    },
  },
}));

const TnC = (props) => {
  const classes = useStyles();

  return (
    <Grid className={classes.root} container justify="center">
      <Grid
        xs={10}
        md={8}
        item
        container
        justify="center"
        className={classes.root1}
      >
        <Typography
          className={classes.text2}
        >{`Terms & Conditions`}</Typography>

        <Typography className={classes.text1}>
          (1) Introduction These terms and conditions govern your use of our
          website; by using our website, you accept these terms and conditions
          in full. If you disagree with any part of these terms and conditions,
          do not use our website.
        </Typography>
        <Typography className={classes.text1}>
          (2) Intellectual property rights Unless otherwise stated, we or our
          licensors own the intellectual property rights in the website and
          material on the website. Subject to the licence below, all our
          intellectual property rights are reserved.
        </Typography>
        <Typography className={classes.text1}>
          (3) Licence to use website You may view, download for caching purposes
          only, and print pages from the website, provided that:
        </Typography>
        <Typography className={classes.text1}>
          (a) you must not republish material from this website (including
          republication on another website), or reproduce or store material from
          this website in any public or private electronic retrieval system;
        </Typography>
        <Typography className={classes.text1}>
          (b) you must not reproduce, duplicate, copy, sell, resell, visit, or
          otherwise exploit our website or material on our website for a
          commercial purpose, without our express written consent;
        </Typography>
        <Typography className={classes.text1}>
          (c) you must not edit or otherwise modify any material on the website.
        </Typography>
        <Typography className={classes.text1}>
          (4) Limitations of liability Some of the information on this website
          is provided free-of-charge, and you acknowledge that it would be
          unreasonable to hold us liable in respect of this website and the
          information on this website. Whilst we endeavour to ensure that the
          information on this website is correct, we do not warrant its
          completeness or accuracy; nor do we not commit to ensuring that the
          website remains available or that the material on this website is kept
          up-to-date.
        </Typography>
        <Typography className={classes.text1}>
          To the maximum extent permitted by applicable law we exclude all
          representations, warranties and conditions (including, without
          limitation, the conditions implied by law of satisfactory quality,
          fitness for purpose and the use of reasonable care and skill).
        </Typography>
        <Typography className={classes.text1}>
          Our liability is limited and excluded to the maximum extent permitted
          under applicable law. We will not be liable for any direct, indirect
          or consequential loss or damage arising under these terms and
          conditions or in connection with our website, whether arising in tort,
          contract, or otherwise – including, without limitation, any loss of
          profit, contracts, business, goodwill, data, income, revenue or
          anticipated savings. However, nothing in these terms and conditions
          shall exclude or limit our liability for fraud, for death or personal
          injury caused by our negligence, or for any other liability which
          cannot be excluded or limited under applicable law.
        </Typography>
        <Typography className={classes.text1}>
          (5) Restricted access to certain areas of our website is restricted.
          We reserve the right to restrict access to other areas of our website,
          or indeed our whole website, at our discretion. If we provide you with
          a user ID and password to enable you to access restricted areas of our
          website or other content or services, you must ensure that that user
          ID and password is kept confidential. You accept responsibility for
          all activities that occur under your user ID or password. We may
          disable your user ID and password at our sole discretion or if you
          breach any of the policies or terms governing your use of our website
          or any other contractual obligation you owe to us.
        </Typography>
        <Typography className={classes.text1}>
          (6) Variation We may revise these terms and conditions from
          time-to-time. Please check this page regularly to ensure you are
          familiar with the current version.
        </Typography>
        <Typography className={classes.text1}>
          (7) Entire agreement These terms and conditions, together with our
          privacy policy, constitute the entire agreement between you and us in
          relation to your use of our website, and supersede all previous
          agreements in respect of your use of this website.
        </Typography>

        <Typography className={classes.text1}>
          (8) Law and jurisdiction This notice will be governed by and construed
          in accordance with Indian law, and any disputes relating to this
          notice shall be subject to the exclusive jurisdiction of the courts of
          Delhi, India.
        </Typography>
        <Typography className={classes.text1}>
          (9) Our contact details You can contact us by contacting us via our
          helpdesk:{" "}
          <NavLink to="/contact" className={classes.link}>
            CONTACT US
          </NavLink>
        </Typography>
      </Grid>
      <Footer />
    </Grid>
  );
};

export default TnC;
