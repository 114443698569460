import React from "react";
import { makeStyles, Grid, Typography, Button } from "@material-ui/core";
import DOG from "../../../assets/images/dog.svg";
import yesImg from "../../../assets/images/yesImg.svg";
import noImg from "../../../assets/images/noImg.svg";

import { QUICKSAND_REG, ROCKWELL_REG, INTER } from "../../../GlobalConstants";

const useStyles = makeStyles((theme) => ({
  question: {
    width: "60%",
    //maxHeight: "80vh",
    backgroundColor: theme.palette.primary.light,
    borderRadius: 20,
    paddingTop: "4%",
    paddingBottom: "2%",
    [theme.breakpoints.down("sm")]: {
      width: "72%",
      paddingTop: "10%",
      paddingBottom: "10%",
      height: "80%",
    },
  },
  questionTxt1Wrapper: {
    alignContent: "flex-end",
    //paddingTop: "10%",
  },
  questionTxt2Wrapper: {
    marginBottom: "3%",
    alignContent: "flex-start",
    //paddingTop: "10%",
  },

  questionTxt: {
    fontSize: 35,
    fontWeight: "bold",
    fontFamily: QUICKSAND_REG,
    color: theme.palette.primary.dark,
    [theme.breakpoints.down("sm")]: {
      fontSize: 22,
    },
  },
  yesBtnWrapper: {
    justifyContent: "flex-end",
    marginTop: "4%",
    paddingRight: "2%",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      paddingRight: 0,
    },
  },
  yesBtn: {
    height: 60,
    width: 150,
    [theme.breakpoints.down("sm")]: {
      height: 50,
      width: 120,
    },
  },
  noBtnWrapper: {
    marginTop: "4%",
    justifyContent: "flex-start",
    paddingLeft: "2%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "8%",
      justifyContent: "center",
      paddingLeft: "0%",
    },
  },
  noBtn: {
    height: 60,
    width: 150,
    [theme.breakpoints.down("sm")]: {
      height: 50,
      width: 120,
    },
  },
  btnText: {
    fontFamily: INTER,
    fontSize: 26,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  imgQuest: {
    paddingBottom: "4%",
    width: "40%",
    height: "40%",
    [theme.breakpoints.up("md")]: {
      marginRight: "10%",
    },

    [theme.breakpoints.down("sm")]: {
      width: 170,
      height: 100,
    },
  },
  yesBtnImg: {
    [theme.breakpoints.down("sm")]: {
      width: 17.04,
      height: 12.43,
    },
  },
  noBtnImg: {
    [theme.breakpoints.down("sm")]: {
      width: 14.64,
      height: 14.64,
    },
  },
}));

const Question1 = (props) => {
  const classes = useStyles();

  const yesHandler = () => {
    props.setCurrentQuest(props.currentQuest + 1);
    props.setData((prevData) => {
      let tempData = { ...prevData };
      let tempDataQuest = { ...tempData[1] };
      tempDataQuest.answer = "Yes";
      tempData[1] = tempDataQuest;
      return tempData;
    });
  };

  const noHandler = () => {
    props.setCurrentQuest(props.currentQuest + 1);
    props.setData((prevData) => {
      let tempData = { ...prevData };
      let tempDataQuest = { ...tempData[1] };
      tempDataQuest.answer = "No";
      tempData[1] = tempDataQuest;
      return tempData;
    });
  };

  return (
    <Grid className={classes.question} container justify="center">
      <Grid xs={12} item container justify="center">
        <img src={DOG} alt="pet" className={classes.imgQuest} />
        <Grid
          container
          justify="center"
          className={classes.questionTxt1Wrapper}
        >
          <Typography className={classes.questionTxt}>Does your dog</Typography>
        </Grid>
        <Grid
          container
          justify="center"
          className={classes.questionTxt2Wrapper}
        >
          <Typography className={classes.questionTxt}>
            bark uncontrollably?
          </Typography>
        </Grid>
        <Grid xs={12} md={6} item container className={classes.yesBtnWrapper}>
          <Button
            className={classes.yesBtn}
            onClick={yesHandler}
            endIcon={
              <img alt="tick" src={yesImg} className={classes.yesBtnImg} />
            }
          >
            <Typography className={classes.btnText}>Yes</Typography>
          </Button>
        </Grid>
        <Grid xs={12} md={6} item container className={classes.noBtnWrapper}>
          <Button
            onClick={noHandler}
            className={classes.noBtn}
            endIcon={
              <img alt="cross" src={noImg} className={classes.noBtnImg} />
            }
          >
            <Typography className={classes.btnText}>No</Typography>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Question1;
