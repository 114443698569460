import React from "react";
import RightArrow from "../../../../assets/images/RightArrow.svg";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    outline: "none",
    transition: "all 0.5s",
    //borderRadius: 35,
    zIndex: 1000,
    border: "none",
    background: "#ffffff",
    minWidth: 43,
    minHeight: 43,
    opacity: 1,
    cursor: "pointer",
    right: "2%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));
const CustomRightArrow = ({ onClick, ...rest }) => {
  const classes = useStyles();
  const {
    onMove,
    carouselState: { currentSlide, deviceType },
  } = rest;
  // onMove means if dragging or swiping in progress.
  return (
    <button onClick={() => onClick()} className={classes.root}>
      <img alt="left" src={RightArrow} />
    </button>
  );
};

export default CustomRightArrow;
