import React from "react";
import {
  makeStyles,
  Grid,
  Typography,
  ClickAwayListener,
} from "@material-ui/core";
import POPULARBLOG1 from "../../../../assets/images/POPULARBLOG1.svg";
import BOOKMARK from "../../../../assets/images/BOOKMARK.svg";
import { ROCKWELL_REG, QUICKSAND_REG } from "../../../../GlobalConstants";
const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: "7%",
    },
  },
  headerTxtWrapper: {},
  headerTxt: {
    fontSize: 16,
    fontFamily: ROCKWELL_REG,
    color: theme.palette.primary.dark,
    textAlign: "left",
  },
  imgWrapper: {
    justifyContent: "flex-end",
    alignContent: "flex-start",
    "&:hover": {
      cursor: "pointer",
    },
  },
  dateWrapper: {
    alignContent: "center",
  },
  bookMarkWrapper: {
    alignContent: "center",
  },
  dateTxt: {
    fontSize: 14,
    fontFamily: QUICKSAND_REG,
    color: theme.palette.primary.dark,
  },
  bookMark: {},
}));

const More1 = (props) => {
  const classes = useStyles();

  const gotoBlog = () => {
    props.history.push("/blogDetail1");
  };
  return (
    <Grid xs={12} md={4} item container className={classes.root}>
      <Grid md={6} xs={7} item container>
        <Grid md={12} xs={12} item className={classes.headerTxtWrapper}>
          <Typography className={classes.headerTxt}>
            5 Basic Commands To Train Your Dog
          </Typography>
        </Grid>
        <Grid md={12} xs={12} item container>
          <Grid xs={8} md={6} item container className={classes.dateWrapper}>
            <Typography className={classes.dateTxt}>08.10.2020</Typography>
          </Grid>
          <Grid xs={2} item container className={classes.bookMarkWrapper}>
            <img alt="bookmark" src={BOOKMARK} className={classes.bookMark} />
          </Grid>
        </Grid>
      </Grid>
      <Grid md={4} xs={5} item container className={classes.imgWrapper}>
        <img alt="pic" src={POPULARBLOG1} onClick={gotoBlog} />
      </Grid>
    </Grid>
  );
};

export default More1;
