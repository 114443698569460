import React from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import { ROCKWELL_REG, QUICKSAND_REG } from "../../../GlobalConstants";
import BLOG1IMG from "../../../assets/images/BlogDetailIMG5.jpg";
import BOOKMARK from "../../../assets/images/BOOKMARK.svg";

import { NavLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "4%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "5%",
      paddingRight: "5%",
      paddingBottom: "4%",
    },
  },
  headingTxtWrapper: {
    paddingTop: "2%",
    // maxHeight: 20,
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "4%",
    },
  },
  headingTxt: {
    textDecoration: "none",
    fontFamily: ROCKWELL_REG,
    fontSize: 16,
    color: theme.palette.primary.dark,
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "80%",
      lineHeight: 1.2,
    },
  },
  descTxtWrapper: {},
  descTxt: {
    maxWidth: "94%",
    textAlign: "left",
    fontFamily: QUICKSAND_REG,
    fontSize: 14,
    color: theme.palette.primary.dark,
    lineHeight: "1.2em",

    [theme.breakpoints.down("sm")]: {
      fontSize: 13,
      maxWidth: "85%",
      maxHeight: "3.6em",
      overflow: "hidden",
      marginBottom: "5%",
    },
  },
  imgWrapper: {
    justifyContent: "flex-end",
  },
  dateWrapper: {},
  dateTxt: {
    fontSize: 14,
    fontFamily: QUICKSAND_REG,
    color: theme.palette.primary.dark,
  },
  readLink: {
    fontSize: 14,
    fontFamily: ROCKWELL_REG,
    color: "#B2B0B0",
    marginLeft: "1%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  dot: {
    height: 5,
    width: 5,
    borderRadius: 5,
    backgroundColor: "#B2B0B0",
    marginTop: "1.4%",
    marginLeft: "3%",
  },
  bookMark: {
    marginLeft: "6%",
    marginTop: "-4%",
  },
  blogImg: {
    height: 164,
    width: 164,
    [theme.breakpoints.down("sm")]: {
      width: 88,
      height: 88,
    },
  },
}));

const Blog5 = (props) => {
  const classes = useStyles();

  return (
    <Grid xs={12} item container className={classes.root}>
      <Grid xs={8} item container className={classes.txtWrapper}>
        <Grid container>
          <Grid xs={12} item container className={classes.headingTxtWrapper}>
            <NavLink to="/blogDetail5" className={classes.headingTxt}>
              Planning for Puppy’s First Bath? Here is the Guide For You
            </NavLink>
          </Grid>
          <Grid xs={12} item container className={classes.descTxtWrapper}>
            <Typography className={classes.descTxt}>
              Puppies, just like kids, constantly drive to getting muddy fast as
              they explore the world around them.
            </Typography>
          </Grid>
          <Typography className={classes.dateTxt}>08.10.2020</Typography>
          <Grid
            className={classes.dot}
            justify="center"
            container
            alignContent="center"
          />

          <NavLink to="/blogDetail5" className={classes.readLink}>
            Read Blogpost
          </NavLink>
          <img alt="bookmark" src={BOOKMARK} className={classes.bookMark} />
        </Grid>
      </Grid>
      <Grid xs={4} item container className={classes.imgWrapper}>
        <img alt="blog" src={BLOG1IMG} className={classes.blogImg} />
      </Grid>
    </Grid>
  );
};

export default Blog5;
