import React, { useState } from "react";
import { makeStyles, Grid, Fade } from "@material-ui/core";
import LT1 from "../../assets/images/LTP1.svg";
import LT2 from "../../assets/images/LTP2.svg";
import LB1 from "../../assets/images/LBP1.svg";
import LB2 from "../../assets/images/LB2.svg";
import RB1 from "../../assets/images/RBP1.svg";
import RB2 from "../../assets/images/RBP2.svg";
import RB3 from "../../assets/images/RBP3.svg";
import PINKBKG from "../../assets/images/PINKBKG.svg";
import PINKBKG_MOB from "../../assets/images/PINKBKG_MOB.svg";

import Question1 from "./Components/Question1";
import HowWorks from "./Components/HowWorks";
import Footer from "../statics/Footer";
import Question2 from "./Components/Question2";
import Question3 from "./Components/Question3";
import Question4 from "./Components/Question4";
const DEFAULT = {
  1: {
    question: "Does your dog bark uncontrollably",
    answer: "No",
  },
  2: {
    question: "Is your dog aggressive? ",
    answer: "No",
  },
  3: {
    question:
      "Are you worried you might have to give up your dog because of behaviour  problems you can’t handle? ",
    answer: "No",
  },
  4: {
    question:
      "Your dog has a fear of certain sounds or stimuli or suffers from anxiety?",
    answer: "No",
  },
};
const useStyles = makeStyles((theme) => ({
  root1: {
    marginTop: 76,
    backgroundColor: theme.palette.primary.main,
    minHeight: "100%",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      marginTop: 60,
    },
  },

  LT1Container: {
    position: "absolute",
    left: "-3.5%",
    [theme.breakpoints.down("sm")]: {
      left: -30,
    },
  },
  LT1: {
    [theme.breakpoints.down("sm")]: {
      width: 85.82,
      height: 108.22,
    },
  },
  LT2Container: {
    position: "absolute",
    top: "4%",
    left: "7%",
    [theme.breakpoints.down("sm")]: {
      top: "4%",
      left: 50,
    },
  },
  LT2: {
    [theme.breakpoints.down("sm")]: {
      width: 65.82,
      height: 115.22,
    },
  },
  LB1Container: {
    position: "absolute",
    left: "-0.5%",
    bottom: -4,
    [theme.breakpoints.down("sm")]: {},
  },
  LB1: {
    [theme.breakpoints.down("sm")]: {
      width: 66.82,
      height: 50.22,
    },
  },
  LB2Container: {
    position: "absolute",
    left: "18%",
    bottom: "16%",
    [theme.breakpoints.down("sm")]: {},
  },
  LB2: {
    [theme.breakpoints.down("sm")]: {
      width: 49.82,
      height: 69.22,
    },
  },
  RB1Container: {
    position: "absolute",
    right: "10%",
    bottom: "0%",
    overflow: "hidden",
    maxHeight: 106,
    [theme.breakpoints.down("sm")]: {
      maxHeight: 65,
      bottom: "0%",
      right: "13%",
    },
  },
  RB1: {
    [theme.breakpoints.down("sm")]: {
      width: 49.82,
      height: 69.22,
    },
  },
  RB2Container: {
    position: "absolute",
    right: "0.5%",
    bottom: "1%",
    overflow: "hidden",
    maxWidth: 145,
    [theme.breakpoints.down("sm")]: {
      maxWidth: 65,
      bottom: 5,
      //right: -10,
    },
  },
  RB2: {
    [theme.breakpoints.down("sm")]: {
      width: 75.82,
      height: 99.22,
    },
  },
  RB3Container: {
    position: "absolute",
    bottom: "29%",
    right: "0%",
    maxWidth: 94,
    overflow: "hidden",

    [theme.breakpoints.down("sm")]: {
      position: "fixed",

      maxWidth: 40,
      //right: "0.5%",
      bottom: 110,

      //right: -10,
    },
  },
  RB3: {
    [theme.breakpoints.down("sm")]: {
      width: 44.82,
      height: 43.22,
    },
  },

  root: {
    paddingTop: "4%",
    paddingBottom: "4%",
    backgroundImage: `url(${PINKBKG})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "83vh",

    [theme.breakpoints.down("sm")]: {
      /*  paddingTop: "20%",
      paddingBottom: "20%", */
      backgroundImage: `url(${PINKBKG_MOB})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: "82vh",
      paddingLeft: "2%",
      paddingRight: "2%",
      alignItems: "center",
    },
  },
}));
const Questions = React.forwardRef((props, ref) => {
  const [currentQuest, setCurrentQuest] = useState(1);
  const [data, setData] = useState(DEFAULT);

  const classes = useStyles();

  return (
    <div>
      <div className={classes.root1} style={{ padding: "2.3%" }}>
        <div className={classes.LT1Container}>
          <img src={LT1} alt="1" className={classes.LT1} />
        </div>
        <div className={classes.LT2Container}>
          <img src={LT2} alt="2" className={classes.LT2} />
        </div>
        <div className={classes.LB1Container}>
          <img src={LB1} alt="2" className={classes.LB1} />
        </div>

        <div className={classes.RB1Container}>
          <img src={RB1} alt="2" className={classes.RB1} />
        </div>
        <div className={classes.RB2Container}>
          <img src={RB2} alt="2" className={classes.RB2} />
        </div>
        <div className={classes.RB3Container}>
          <img src={RB3} alt="2" className={classes.RB3} />
        </div>
        {currentQuest === 1 && (
          <Fade in={true} timeout={3000}>
            <Grid className={classes.root} container justify="center">
              <Question1
                currentQuest={currentQuest}
                setCurrentQuest={setCurrentQuest}
                setData={setData}
              />
            </Grid>
          </Fade>
        )}
        {currentQuest === 2 && (
          <Fade in={true} timeout={3000}>
            <Grid className={classes.root} container justify="center">
              <Question2
                currentQuest={currentQuest}
                setCurrentQuest={setCurrentQuest}
                setData={setData}
              />
            </Grid>
          </Fade>
        )}
        {currentQuest === 3 && (
          <Fade in={true} timeout={3000}>
            <Grid className={classes.root} container justify="center">
              <Question3
                currentQuest={currentQuest}
                setCurrentQuest={setCurrentQuest}
                setData={setData}
              />
            </Grid>
          </Fade>
        )}
        {currentQuest === 4 && (
          <Fade in={true} timeout={3000}>
            <Grid className={classes.root} container justify="center">
              <Question4
                currentQuest={currentQuest}
                setCurrentQuest={setCurrentQuest}
                setData={setData}
                data={data}
                DEFAULT={DEFAULT}
                {...props}
              />
            </Grid>
          </Fade>
        )}
      </div>

      <HowWorks />
      <Footer />
    </div>
  );
});

export default Questions;
