import React from "react";
import { makeStyles, Grid, Paper, Typography, Button } from "@material-ui/core";
import BLOG1 from "../../../../assets/images/BlogDetailIMG5.jpg";
import { QUICKSAND_REG, ROCKWELL_REG } from "../../../../GlobalConstants";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 350,
    [theme.breakpoints.down("sm")]: {
      maxWidth: 238,
      overflow: "hidden",
    },
  },
  cardWrapper: {
    borderRadius: 10,
    marginBottom: "10%",
    boxShadow:
      " 0px 1px 3px 0px rgba(0,0,0,0.12),  0px 1px 3px 0px rgba(0,0,0,0.12), 0px 1px 23px 0px rgba(0,0,0,0.12)",
  },
  blogImg: {
    width: 350,
    height: 202,
    [theme.breakpoints.down("sm")]: {
      width: 238,
      height: 137,
    },
  },
  dateWrapper: {
    paddingLeft: "10%",
    paddingTop: "8%",
    paddingBottom: "8%",
  },
  date: {
    fontFamily: QUICKSAND_REG,
    color: theme.palette.primary.dark,
    fontSize: 16,
    textAlign: "left",
  },
  titleWrapper: {
    paddingLeft: "10%",
    maxWidth: "90%",
    paddingBottom: "8%",
  },
  title: {
    fontSize: 16,
    color: theme.palette.primary.dark,
    fontFamily: ROCKWELL_REG,
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },

  descWrapper: {
    paddingLeft: "10%",
    maxWidth: "85%",
    paddingBottom: "8%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "90%",
    },
  },
  desc: {
    fontSize: 14,
    color: theme.palette.primary.dark,
    fontFamily: QUICKSAND_REG,
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      fontSize: 13,
    },
  },
  btnWrapper: {
    paddingLeft: "10%",
    paddingBottom: "8%",
  },
  btnReadMore: {
    paddingRight: "4%",
    paddingLeft: "2%",
    borderRadius: 3,
    fontFamily: ROCKWELL_REG,
    fontSize: 14,
    color: theme.palette.primary.dark,
    backgroundColor: "#F0F0F0",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "10%",
      paddingLeft: "4%",
    },
  },
}));

const BlogCard5 = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const gotoBlog = () => {
    props.history.push("/blogDetail5");
  };

  return (
    <Grid className={classes.root}>
      <Paper className={classes.cardWrapper}>
        <img alt="blog" src={BLOG1} className={classes.blogImg} />
        <Grid className={classes.dateWrapper}>
          <Typography className={classes.date}>{`08.10.2020`}</Typography>
        </Grid>
        <Grid container className={classes.titleWrapper} justify="flex-start">
          <Typography className={classes.title}>
            Planning for Puppy’s First Bath? Here is the Guide For You
          </Typography>
        </Grid>
        <Grid container className={classes.descWrapper}>
          <Typography className={classes.desc}>
            Puppies, just like kids, constantly drive to getting muddy fast as
            they explore the world around them.
          </Typography>
        </Grid>
        <Grid container className={classes.btnWrapper}>
          <Button className={classes.btnReadMore} onClick={gotoBlog}>
            Read More
          </Button>
        </Grid>
      </Paper>
    </Grid>
  );
});

export default BlogCard5;
