export const LOGOUT_ACTION = "LOGOUT";

export const ROCKWELL_REG = "rockwell";
export const PROGRAMOT_REG = "program";
export const QUICKSAND_REG = "quicksand";
export const INTER = "Inter";

export const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
