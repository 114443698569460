import React, { useState } from "react";
import {
  makeStyles,
  Grid,
  Typography,
  TextField,
  Button,
} from "@material-ui/core";
import { ROCKWELL_REG, QUICKSAND_REG, INTER } from "../../../GlobalConstants";
import BUTTON_ARROW from "../../../assets/images/BUTTON_ARROW.svg";
import { Redirect } from "react-router-dom";
const _ = require("lodash");
const useStyles = makeStyles((theme) => ({
  question: {
    //height: "70%",
    width: "60%",
    backgroundColor: theme.palette.primary.light,
    borderRadius: 20,
    paddingTop: "5%",
    [theme.breakpoints.down("sm")]: {
      width: "70%",
      paddingTop: "8%",
      paddingLeft: "2%",
      paddingLeft: "2%",
    },
  },
  txt1Wrapper: {
    paddingBottom: "5%",
  },
  txt1: {
    fontSize: 56,
    fontFamily: ROCKWELL_REG,
    color: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      fontSize: 29,
    },
  },

  txt2Wrapper: {},
  txt2: {
    fontFamily: QUICKSAND_REG,
    fontSize: 33,
    fontWeight: "bold",
    minWidth: "100%",
    color: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  txt2_1: {
    fontFamily: QUICKSAND_REG,
    fontSize: 22,
    fontWeight: "bold",
    textAlign: "center",
    color: theme.palette.primary.dark,
    lineHeight: 1.5,
    marginBottom: "5%",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  txt3Wrapper: {
    paddingBottom: "3%",
    paddingTop: "5%",
  },
  txt3: {
    fontFamily: QUICKSAND_REG,
    fontSize: 22,
    fontWeight: "bold",
    minWidth: "100%",
    color: theme.palette.primary.dark,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  txt3_1: {
    fontFamily: QUICKSAND_REG,
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "center",
    color: theme.palette.primary.dark,
    lineHeight: 1.5,
    marginBottom: "5%",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  btnWrapper: {
    paddingBottom: "4%",
  },
  btn: {
    backgroundColor: theme.palette.primary.main,
    width: "50%",
    height: 60,
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      height: 40,
    },
  },
  btnTxt: {
    fontFamily: INTER,
    fontSize: 26,
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
}));

const ThankYou = (props) => {
  const classes = useStyles();

  const submitHandler = () => {
    props.history.push("/congratulations");
  };

  return (
    <Grid className={classes.question}>
      <Grid xs={12} item className={classes.txt1Wrapper}>
        <Typography className={classes.txt1}>Thank You!</Typography>
      </Grid>
      <Grid xs={12} item className={classes.txt2Wrapper}>
        <Typography className={classes.txt2}>
          We’ve sent you a customised
        </Typography>
        <Typography className={classes.txt2}>
          free game on your email address.
        </Typography>
        <Typography className={classes.txt2_1}>
          We’ve sent you a customised free game on your email address.
        </Typography>
      </Grid>
      <Grid xs={12} item className={classes.txt3Wrapper}>
        <Typography className={classes.txt3}>
          Please check your spam if you can’t find the
        </Typography>
        <Typography className={classes.txt3}>
          Game in few minutes and add us to your
        </Typography>
        <Typography className={classes.txt3}>
          contact list for future games and assets.{" "}
        </Typography>
        <Typography className={classes.txt3_1}>
          Please check your spam if you can’t find the Game in few minutes and
          add us to your contact list for future games and assets.
        </Typography>
      </Grid>
      <Grid
        xs={12}
        item
        container
        justify="center"
        className={classes.btnWrapper}
      >
        <Button
          className={classes.btn}
          target="_blank"
          href="https://8826ecpqwkvr2nez22sd36sb2x.hop.clickbank.net/?tid=FF"
        >
          <Typography className={classes.btnTxt}>
            Check Complete Guide
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export default ThankYou;
