import React, { useState } from "react";
import { makeStyles, Grid } from "@material-ui/core";
import LT1 from "../../assets/images/LTP1.svg";
import LT2 from "../../assets/images/LTP2.svg";
import LB1 from "../../assets/images/LBP1.svg";
import RB1 from "../../assets/images/RBP1.svg";
import RB2 from "../../assets/images/RBP2.svg";
import RB3 from "../../assets/images/RBP3.svg";
import PINKBKG from "../../assets/images/PINKBKG.svg";
import Footer from "../statics/Footer";
import VideoScreen from "./components/VideoScreen";
import PINKBKG_MOB from "../../assets/images/PINKBKG_MOB.svg";
import MainSection from "./components/MainSection";

const useStyles = makeStyles((theme) => ({
  root1: {
    marginTop: 76,
    backgroundColor: theme.palette.primary.main,
    // minHeight: 615,
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      marginTop: 60,
    },
  },

  LT1Container: {
    position: "absolute",
    left: "-3.5%",
    [theme.breakpoints.down("sm")]: {
      left: -30,
    },
  },
  LT1: {
    [theme.breakpoints.down("sm")]: {
      width: 85.82,
      height: 108.22,
    },
  },
  LT2Container: {
    position: "absolute",
    top: "4%",
    left: "7%",
    [theme.breakpoints.down("sm")]: {
      top: "4%",
      left: 50,
    },
  },
  LT2: {
    [theme.breakpoints.down("sm")]: {
      width: 65.82,
      height: 115.22,
    },
  },
  LB1Container: {
    position: "absolute",
    left: "-0.5%",
    bottom: -4,
    [theme.breakpoints.down("sm")]: {},
  },
  LB1: {
    [theme.breakpoints.down("sm")]: {
      width: 66.82,
      height: 50.22,
    },
  },
  LB2Container: {
    position: "absolute",
    left: "18%",
    bottom: "16%",
    [theme.breakpoints.down("sm")]: {},
  },
  LB2: {
    [theme.breakpoints.down("sm")]: {
      width: 49.82,
      height: 69.22,
    },
  },
  RB1Container: {
    position: "absolute",
    right: "10%",
    bottom: "0%",
    overflow: "hidden",
    maxHeight: 106,
    [theme.breakpoints.down("sm")]: {
      maxHeight: 65,
      bottom: "0%",
      right: "13%",
    },
  },
  RB1: {
    [theme.breakpoints.down("sm")]: {
      width: 49.82,
      height: 69.22,
    },
  },
  RB2Container: {
    position: "absolute",
    right: "0.5%",
    bottom: "1%",
    overflow: "hidden",
    maxWidth: 145,
    [theme.breakpoints.down("sm")]: {
      maxWidth: 65,
      bottom: 5,
      //right: -10,
    },
  },
  RB2: {
    [theme.breakpoints.down("sm")]: {
      width: 75.82,
      height: 99.22,
    },
  },
  RB3Container: {
    position: "absolute",
    bottom: "29%",
    right: "0%",
    maxWidth: 94,
    overflow: "hidden",

    [theme.breakpoints.down("sm")]: {
      maxWidth: 40,
      //right: "0.5%",
      bottom: 110,

      //right: -10,
    },
  },
  RB3: {
    [theme.breakpoints.down("sm")]: {
      width: 44.82,
      height: 43.22,
    },
  },

  root: {
    paddingTop: "2.3%",
    paddingBottom: "2.3%",
    backgroundImage: `url(${PINKBKG})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "89vh",

    [theme.breakpoints.down("sm")]: {
      height: "83vh",

      /*  paddingTop: "20%",
      paddingBottom: "20%", */
      backgroundImage: `url(${PINKBKG_MOB})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      alignContent: "center",
    },
  },
}));
const Congratulations = React.forwardRef((props, ref) => {
  const classes = useStyles();

  return (
    <div>
      <div
        className={classes.root1}
        style={{ padding: "1%", paddingBottom: 0 }}
      >
        <div className={classes.LT1Container}>
          <img src={LT1} alt="1" className={classes.LT1} />
        </div>
        <div className={classes.LT2Container}>
          <img src={LT2} alt="2" className={classes.LT2} />
        </div>
        <div className={classes.LB1Container}>
          <img src={LB1} alt="2" className={classes.LB1} />
        </div>

        <div className={classes.RB1Container}>
          <img src={RB1} alt="2" className={classes.RB1} />
        </div>
        <div className={classes.RB2Container}>
          <img src={RB2} alt="2" className={classes.RB2} />
        </div>
        <div className={classes.RB3Container}>
          <img src={RB3} alt="2" className={classes.RB3} />
        </div>
        <Grid className={classes.root} container justify="center">
          <MainSection {...props} />
        </Grid>
      </div>
      <VideoScreen />
      <Footer />
    </div>
  );
});

export default Congratulations;
