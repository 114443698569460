import React, { useEffect } from "react";
import { makeStyles, Grid, Typography, Button } from "@material-ui/core";
import {
  PROGRAMOT_REG,
  ROCKWELL_REG,
  QUICKSAND_REG,
  INTER,
} from "../../../GlobalConstants";
import BUTTON_ARROW from "../../../assets/images/BUTTON_ARROW.svg";
import QUIZ_BKG from "../../../assets/images/QUIZ_BKG.svg";
import QUIZ_BKG_SM from "../../../assets/images/QUIZ_BKG_SM.svg";

import LT1 from "../../../assets/images/LT1.svg";
import LT2 from "../../../assets/images/LT2.svg";
import LB1 from "../../../assets/images/LB1.svg";
import LB2 from "../../../assets/images/LB2.svg";
import RB1 from "../../../assets/images/RB1.svg";
import RB2 from "../../../assets/images/RB2.svg";
import RB3 from "../../../assets/images/RB3.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "10%",
    paddingBottom: "10%",
    backgroundImage: `url(${QUIZ_BKG})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    [theme.breakpoints.down("sm")]: {
      height: "83vh",

      backgroundImage: `url(${QUIZ_BKG_SM})`,
      paddingLeft: "5%",
      paddingRight: "5%",
      paddingTop: "20%",
      paddingBottom: "13%",
      /*  borderBottomWidth: 2,
      borderBottomStyle: "solid", */
    },
  },
  itemsWrapper1: {
    backgroundColor: "transparent",
    [theme.breakpoints.down("sm")]: {
      //paddingBottom: "4%",
    },
  },
  text1: {
    fontSize: 56,
    color: theme.palette.primary.main,
    marginBottom: 10,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  text1_1: {
    fontSize: 28,
    fontWeight: "bold",
    color: theme.palette.primary.main,
    fontFamily: ROCKWELL_REG,
    minWidth: "100%",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  text2_1: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    fontSize: 22,
    fontWeight: "500",
    color: theme.palette.primary.dark,
    fontFamily: QUICKSAND_REG,
  },

  text2_3: {
    /*  textDecoration: "underline ",
    textDecorationThickness: 5,

    textDecorationColor: "#FFEAA7",
    textDecorationStyle: "solid", */
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    fontSize: 22,
    fontWeight: "bolder",
    color: theme.palette.primary.dark,
    fontFamily: QUICKSAND_REG,
    minWidth: "100%",
  },
  text2: {
    fontSize: 54,
    color: theme.palette.primary.dark,
    fontFamily: PROGRAMOT_REG,
    marginBottom: -10,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  text3: {
    fontSize: 54,
    color: theme.palette.primary.dark,
    fontFamily: PROGRAMOT_REG,
    marginBottom: -10,
    fontWeight: "bold",
    /*  textDecoration: "underline ",
    textDecorationColor: "#FFEAA7",
    textDecorationStyle: "solid", */
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  btn1: {
    marginTop: "6%",
    fontFamily: ROCKWELL_REG,
    fontSize: 26,
    paddingTop: "1%",
    paddingBottom: "1%",
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "68%",
      //paddingTop: "5%",
      // paddingBottom: "5%",
      height: 70,
      paddingRight: "8%",
      paddingLeft: "3%",
      marginTop: "4%",
      marginBottom: "4%",
    },
    //maxWidth: "100%",
  },
  btn1Img: {
    paddingLeft: "54%",
  },
  bt1Text: {
    textAlign: "center",
    fontFamily: INTER,
    verticalAlign: "middle",
    fontSize: 26,
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  itemsWrapper: {
    backgroundColor: "transparent",
  },
  LT1Container: {
    position: "absolute",
    left: "-3.5%",
    [theme.breakpoints.down("sm")]: {
      left: -32,
      top: 95,
    },
  },
  LT1: {
    [theme.breakpoints.down("sm")]: {
      width: 100,
      height: 115,
    },
  },
  LT2Container: {
    position: "absolute",
    top: "4%",
    left: "7%",
    [theme.breakpoints.down("sm")]: {
      top: 47,
      left: 50,
    },
  },
  LT2: {
    [theme.breakpoints.down("sm")]: {
      width: 100,
      height: 115,
    },
  },
  LB1Container: {
    position: "absolute",
    left: "-0.5%",
    bottom: 0,
  },
  LB1: {
    [theme.breakpoints.down("sm")]: {
      width: 66,
      height: 50,
    },
  },
  LB2Container: {
    position: "absolute",
    left: "17%",
    bottom: "12%",
    [theme.breakpoints.down("sm")]: {
      left: 10,
      bottom: 180,
    },
  },
  LB2: {
    [theme.breakpoints.down("sm")]: {
      width: 70,
      height: 79,
    },
  },
  RB1Container: {
    position: "absolute",
    right: "10%",
    bottom: "1%",
    overflow: "hidden",
    maxHeight: 115,
    [theme.breakpoints.down("sm")]: {
      maxHeight: 60,

      right: 70,
      bottom: -5,
      overflow: "hidden",
    },
  },
  RB1: {
    [theme.breakpoints.down("sm")]: {
      width: 50,
      height: 70,
    },
  },
  RB2Container: {
    position: "absolute",
    right: "0.5%",
    bottom: "1%",
    overflow: "hidden",
    maxWidth: 145,
    [theme.breakpoints.down("sm")]: {
      maxWidth: 65,
    },
  },
  RB2: {
    [theme.breakpoints.down("sm")]: {
      width: 80,
      height: 105,
    },
  },
  RB3Container: {
    position: "absolute",
    right: "0%",
    bottom: "26%",
    overflow: "hidden",

    [theme.breakpoints.down("sm")]: {
      maxWidth: 40,
      bottom: 110,
    },
  },
  RB3: {
    [theme.breakpoints.down("sm")]: {
      width: 50,
      height: 49,
    },
  },
}));
const Quiz = React.forwardRef((props, ref) => {
  const classes = useStyles();

  const gotoQuestions = () => {
    props.history.push("/questions");
  };
  return (
    <div style={{ padding: "1%" }}>
      <div className={classes.LT1Container}>
        <img src={LT1} alt="1" className={classes.LT1} />
      </div>
      <div className={classes.LT2Container}>
        <img src={LT2} alt="2" className={classes.LT2} />
      </div>
      <div className={classes.LB1Container}>
        <img src={LB1} alt="2" className={classes.LB1} />
      </div>
      <div className={classes.LB2Container}>
        <img src={LB2} alt="2" className={classes.LB2} />
      </div>
      <div className={classes.RB1Container}>
        <img src={RB1} alt="2" className={classes.RB1} />
      </div>
      <div className={classes.RB2Container}>
        <img src={RB2} alt="2" className={classes.RB2} />
      </div>
      <div className={classes.RB3Container}>
        <img src={RB3} alt="2" className={classes.RB3} />
      </div>
      <Grid className={classes.root} container justify="center">
        <Grid
          xs={12}
          item
          container
          justify="center"
          className={classes.itemsWrapper1}
        >
          <Typography className={classes.text1}>
            Answer 4 Quick Questions
          </Typography>
          <Typography className={classes.text1_1} style={{ lineHeight: "0" }}>
            Answer 4
          </Typography>
          <Typography className={classes.text1_1} style={{ lineHeight: "0" }}>
            Quick Questions
          </Typography>
        </Grid>
        <Grid
          xs={12}
          item
          container
          justify="center"
          className={classes.itemsWrapper}
        >
          <Typography className={classes.text2}>
            to receive suitable easy to follow guidelines
          </Typography>
          <Typography
            className={classes.text2_1}
            style={{ lineHeight: "0", marginTop: "3%" }}
          >
            to receive suitable easy
          </Typography>
          <Typography className={classes.text2_1} style={{ lineHeight: "0" }}>
            to follow guidelines on
          </Typography>
        </Grid>
        <Grid
          xs={12}
          item
          container
          justify="center"
          className={classes.itemsWrapper}
        >
          <Typography className={classes.text2}>{`on `}</Typography>
          <Typography className={classes.text2} style={{ fontWeight: "bold" }}>
            “H
          </Typography>
          <Typography className={classes.text3}>
            ow to fix your pet’s behaviou
          </Typography>
          <Typography className={classes.text2} style={{ fontWeight: "bold" }}>
            r?”
          </Typography>

          <Typography className={classes.text2_3} style={{ lineHeight: "0" }}>
            “How to fix your pet’s
          </Typography>
          <Typography className={classes.text2_3} style={{ lineHeight: "0" }}>
            behaviour?”
          </Typography>
        </Grid>
        <Grid xs={12} item container justify="center">
          <Button
            className={classes.btn1}
            onClick={gotoQuestions}
            endIcon={
              <Grid className={classes.btn1Img} container justify="center">
                <img alt="arrow" src={BUTTON_ARROW} />
              </Grid>
            }
          >
            <Typography className={classes.bt1Text}>
              Take Me To The Questions
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </div>
  );
});

export default Quiz;
