import React from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import BlogDetailIMG1 from "../../assets/images/BlogDetailIMG1.svg";
import BlogDetailIMG2 from "../../assets/images/BlogDetailIMG2.svg";
import BlogDetailIMG3 from "../../assets/images/BlogDetailIMG3.svg";

import { ROCKWELL_REG, QUICKSAND_REG } from "../../GlobalConstants";
import Footer from "../statics/Footer";
import Blog8More from "./components/Blog8More";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 76,
    justifyContent: "flex-start",
    paddingLeft: "8%",
    [theme.breakpoints.down("sm")]: {
      marginTop: 61,
      justifyContent: "center",
      paddingLeft: "0",
    },
  },
  img: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },
  grid1: {
    [theme.breakpoints.up("md")]: { marginLeft: "15%" },
  },
  txt1Wrapper: {
    paddingTop: "4%",
    paddingBottom: "4%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "2%",
    },
  },
  txt1: {
    fontFamily: ROCKWELL_REG,
    color: theme.palette.primary.dark,
    fontSize: 30,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  txt1_1: {
    fontFamily: ROCKWELL_REG,
    color: theme.palette.primary.dark,
    fontSize: 22,
    textAlign: "left",
    maxWidth: "70%",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  txt2Wrapper: {
    //paddingBottom: "4%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "2%",
    },
  },
  txt2Wrapper1: {
    paddingTop: "3%",
    paddingBottom: "3%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "2%",
    },
  },
  txt2: {
    fontFamily: QUICKSAND_REG,
    color: theme.palette.primary.dark,
    fontSize: 16,
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
      maxWidth: "90%",
    },
  },
  txt3Wrapper: {
    paddingBottom: "4%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "2%",
      paddingTop: "3%",
    },
  },
  txt3: {
    fontFamily: QUICKSAND_REG,
    color: theme.palette.primary.dark,
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "left",
  },
  img2Wrapper: {
    paddingTop: "3%",
  },
  txt4Wrapper: {
    paddingTop: "2%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "2%",
    },
  },
  txt4: {
    fontFamily: ROCKWELL_REG,
    color: theme.palette.primary.dark,
    fontSize: 25,
    textAlign: "left",
  },
  ul: {
    color: theme.palette.primary.dark,
    padding: 0,
    margin: 0,
    paddingLeft: "3%",
  },
}));

const Blog8Detail = React.forwardRef((props, ref) => {
  const classes = useStyles();

  return (
    <div>
      <Grid container className={classes.root} justify="center">
        <Grid container className={classes.grid1} xs={12} md={5} item>
          <Grid item xs={12} className={classes.img1Wrapper}>
            <img alt="img1" src={BlogDetailIMG1} className={classes.img} />
          </Grid>
          <Grid xs={12} item container className={classes.txt1Wrapper}>
            <Typography className={classes.txt1}>
              Tips to Manage Your Dog In Rainy Season
            </Typography>
            <Typography className={classes.txt1_1}>
              Tips to Manage Your Dog In Rainy Season
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Monsoon is about to come. So, the tips to manage your little pooch
              cannot be so difficult. A little bit of love and care is what they
              need to make them feel warm and protected during this season.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              We have listed down few tips which will guide you on how to manage
              your dog during this rainy season and keep him/her healthy and
              cheerful.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt3Wrapper}>
            <Typography className={classes.txt3}>Let's get started.</Typography>
          </Grid>
          <Grid item xs={12} className={classes.img2Wrapper}>
            <img alt="img2" src={BlogDetailIMG2} className={classes.img} />
          </Grid>
          <Grid xs={12} item container className={classes.txt4Wrapper}>
            <Typography className={classes.txt4}>CLEANLINESS</Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Ticks are prevalent throughout this season; so do make sure you
              check among the paws after every walk. You could also sprinkle a
              natural tick repellent (few droplets of eucalyptus oil in water)
              between the paws before a going for a walk to prevent these bugs.
              If your pooch discard the idea of dog footwear, make sure to wash
              their paws completely and clean them dry each time they come back
              from outdoor. Don’t overdo showers in this climate as it serves to
              drain their fur of its natural oils and settles the skin.
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.img2Wrapper}>
            <img alt="img3" src={BlogDetailIMG3} className={classes.img} />
          </Grid>
          <Grid xs={12} item container className={classes.txt4Wrapper}>
            <Typography className={classes.txt4}>EXERCISE</Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Daily exercise is required. Regrettably, when it rains heavy, your
              pet might drop out on it. Assure your pet doesn’t get too lazy by
              engaging interactive plays around the house. Mystery games,
              performing ‘search’ for his food by sneaking it all around the
              house, a game of fetch or even causing him/her play easy tricks
              like jumping, spinning, crawling etc. within the house are all
              great choices. These exercises will hold your dog involved,
              engaged, and help deliver any pent-up energy.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Indoor games to place your dog involved throughout the rains.
            </Typography>
          </Grid>

          <Grid xs={12} item container className={classes.txt4Wrapper}>
            <Typography className={classes.txt4}>
              FOR SOUND SENSITIVE DOGS
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Some dogs are scared of the noise that follows the monsoon rains.
              The sound of thunders scare them a lot. To aid them to deal with
              the sound, let him/her find a safe spot in your house to hide when
              terrified e.g.: below a sofa, table or bed; also cover up the
              sound by switching on the television. Few dogs might also be
              struck by lightning, so close the curtains of the room in which
              you are sitting so they don’t respond to it.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Don’t try to touch or comfort your pooch if he is afraid, you
              require to perform like nothing is going wrong so that he starts
              to follow the similar feeling.
            </Typography>
          </Grid>

          <Grid xs={12} item container className={classes.txt4Wrapper}>
            <Typography className={classes.txt4}>WATER DOGS</Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Few dog breeds like Labradors like water, so don't refuse your dog
              to dive into the water in this weather if they are excited to do
              so. After all, it will be beneficial for them only.
            </Typography>
          </Grid>

          <Grid xs={12} item container className={classes.txt4Wrapper}>
            <Typography className={classes.txt4}>TOILET TRAINING</Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              For some dogs toilet training grows to be a problem throughout the
              monsoons. Though, assure that you work and hold on to your walk
              routine as much as feasible. If it is your puppy's first monsoon,
              provide him/her some time to adapt to the unusual feeling of
              possessing to go on wet area.
            </Typography>
          </Grid>

          <Grid xs={12} item container className={classes.txt2Wrapper1}>
            <Typography className={classes.txt2}>
              Do make sure that you avoid waterlogged spaces while out for a
              walk with your pets, as that may make them helpless to diseases.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper1}>
            <Typography className={classes.txt2}>
              We hope that these tips will help you out in this rainy season and
              also help your dog to stay fit and healthy with having fun in this
              weather.
            </Typography>
          </Grid>
        </Grid>

        <Grid container className={classes.grid2}></Grid>

        <Grid container className={classes.grid3}></Grid>

        <Blog8More {...props} />
      </Grid>
      <Footer />
    </div>
  );
});

export default Blog8Detail;
