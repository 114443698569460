import React from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import BlogDetailIMG1 from "../../assets/images/BlogDetailIMG6.jpg";
import BlogDetailIMG2 from "../../assets/images/BlogDetailIMG2.svg";
import BlogDetailIMG3 from "../../assets/images/BlogDetailIMG3.svg";

import { ROCKWELL_REG, QUICKSAND_REG } from "../../GlobalConstants";
import Footer from "../statics/Footer";
import Blog6More from "./components/Blog6More";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 76,
    justifyContent: "flex-start",
    paddingLeft: "6%",
    [theme.breakpoints.down("sm")]: {
      marginTop: 61,
      justifyContent: "center",
      paddingLeft: "0",
    },
  },
  img: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },
  grid1: {
    [theme.breakpoints.up("md")]: { marginLeft: "15%" },
  },
  txt1Wrapper: {
    paddingTop: "4%",
    paddingBottom: "4%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "2%",
    },
  },
  txt1: {
    fontFamily: ROCKWELL_REG,
    color: theme.palette.primary.dark,
    fontSize: 30,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  txt1_1: {
    fontFamily: ROCKWELL_REG,
    color: theme.palette.primary.dark,
    fontSize: 22,
    textAlign: "left",
    maxWidth: "70%",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  txt2Wrapper: {
    //paddingBottom: "4%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "2%",
    },
  },
  txt2Wrapper1: {
    paddingTop: "3%",
    paddingBottom: "3%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "2%",
    },
  },
  txt2: {
    fontFamily: QUICKSAND_REG,
    color: theme.palette.primary.dark,
    fontSize: 16,
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
      maxWidth: "90%",
    },
  },
  txt3Wrapper: {
    paddingBottom: "4%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "2%",
      paddingTop: "3%",
    },
  },
  txt3: {
    fontFamily: QUICKSAND_REG,
    color: theme.palette.primary.dark,
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "left",
  },
  img2Wrapper: {
    paddingTop: "3%",
  },
  txt4Wrapper: {
    paddingTop: "2%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "2%",
    },
  },
  txt4: {
    fontFamily: ROCKWELL_REG,
    color: theme.palette.primary.dark,
    fontSize: 25,
    textAlign: "left",
  },
  ul: {
    color: theme.palette.primary.dark,
    padding: 0,
    margin: 0,
    paddingLeft: "3%",
  },
}));

const Blog6Detail = React.forwardRef((props, ref) => {
  const classes = useStyles();

  return (
    <div>
      <Grid container className={classes.root} justify="center">
        <Grid container className={classes.grid1} xs={12} md={5} item>
          <Grid item xs={12} className={classes.img1Wrapper}>
            <img alt="img1" src={BlogDetailIMG1} className={classes.img} />
          </Grid>
          <Grid xs={12} item container className={classes.txt1Wrapper}>
            <Typography className={classes.txt1}>
              Having A Dog Can Improve Your Health
            </Typography>
            <Typography className={classes.txt1_1}>
              Having A Dog Can Improve Your Health
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Are you blessed to have a little pooch in your life? Do you know
              about the health benefits of having a dog in your life? We all
              have been fond of dogs and I personally love to own a dog and have
              him or her around the house. Though, there are many people who
              find it difficult to have a dog at their home. Not to worry if you
              are one of them. This article is about to tell you the health
              benefits of having a dog in your life. How a dog can bring out
              such a big change in your life and make your life better and
              healthier.
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.img2Wrapper}>
            <img alt="img2" src={BlogDetailIMG2} className={classes.img} />
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Having a dog at home seems to have helped people who are more
              prone to stress or already suffering from one. Taking our dog out
              for a small walk near the garden or park, playing catch the ball
              with him surely soothes the stress out of our system and make us
              feel more relaxed.
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.img2Wrapper}>
            <img alt="img3" src={BlogDetailIMG3} className={classes.img} />
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Your dog will start wagging his/her tail in excitement as he/she
              gets back the ball to you, expecting you to throw the ball again
              so he can run to catch it again. These little things really make
              us feel happy and we enjoy them so much. Being a person myself who
              has gone through those days when I use to feel stressed out due to
              some work or anything, I use to find myself at ease just by
              spending few minutes in the presence of my pooch. She uses to make
              me feel happy again and forget the stress or sadness I was facing
              earlier. These were like some magical experiences for me. Their
              presence itself lifts you up.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Whenever you come back to home after a long day, that jump and
              licks we get just after entering the house is a big stress buster.
              The cuddle and warmth we receive by the love they shower upon
              their big buddy make us forget all that had happened during the
              day. We start to cherish that moment of love and warmth. The way
              they jump all across the room, on the couch, makes the home so
              cozy and warm. They make us feel positive and uplifted just with
              their sheer presence.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              The time we spend in our office or at work station makes us a bit
              tired and stressed up. But the moment we think about our little
              bundle of joy waiting for us at home, makes our mood cheered up
              realizing that after going back home we will be getting that
              warmth and love again today! This thought only makes us feel happy
              and put back the smile on our face again. Going out for these
              walks anytime during the day is also a form of exercise. You all
              already know the benefits of doing exercise on a regular basis.
              The fresh air we intake while taking our dog out for the walk also
              make us feel energized. This is another good reason to have a dog.
              The walks not only help your dog to feel refreshed but also allow
              you to take some deep breath in and feel rejuvenated. This allows
              us to clear our minds and think positively which also uplifts our
              mood.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Trust me, having a dog is a blessing! It surely changes your life
              a lot and makes it a happier place. Don't sit back, think of
              owning a dog, and have one soon. You will definitely feel the
              change in the starting few days only.
            </Typography>
          </Grid>
        </Grid>

        <Grid container className={classes.grid2}></Grid>

        <Grid container className={classes.grid3}></Grid>

        <Blog6More {...props} />
      </Grid>
      <Footer />
    </div>
  );
});

export default Blog6Detail;
