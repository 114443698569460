import React from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import { ROCKWELL_REG, QUICKSAND_REG } from "../../../../GlobalConstants";
import POPULARBLOG1 from "../../../../assets/images/BlogDetailIMG6.jpg";
import BOOKMARK from "../../../../assets/images/BOOKMARK.svg";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "12%",
    paddingRight: "5%",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "3%",
    },
  },
  imgBlg: {
    height: 88,
    width: 88,
  },
  headerTxtWrapper: {},
  headerTxt: {
    textDecoration: "none",
    fontSize: 16,
    fontFamily: ROCKWELL_REG,
    color: theme.palette.primary.dark,
    textAlign: "left",
  },
  imgWrapper: {
    justifyContent: "flex-end",
    "&:hover": {
      cursor: "pointer",
    },
  },
  dateWrapper: {
    alignContent: "center",
  },
  bookMarkWrapper: {
    alignContent: "center",
  },
  dateTxt: {
    fontSize: 14,
    fontFamily: QUICKSAND_REG,
    color: theme.palette.primary.dark,
  },
  bookMark: {},
}));

const PopularBlog6 = (props) => {
  const classes = useStyles();
  const gotoBlog = () => {
    props.history.push("/blogDetail6");
  };

  return (
    <Grid xs={12} item className={classes.root}>
      <Grid container>
        <Grid xs={6} item container>
          <Grid xs={12} item className={classes.headerTxtWrapper}>
            <NavLink to="/blogDetail6" className={classes.headerTxt}>
              <Typography>Having A Dog Can Improve Your Health</Typography>
            </NavLink>
          </Grid>
          <Grid xs={12} item container>
            <Grid xs={6} item container className={classes.dateWrapper}>
              <Typography className={classes.dateTxt}>08.10.2020</Typography>
            </Grid>
            <Grid xs={2} item container className={classes.bookMarkWrapper}>
              <img alt="bookmark" src={BOOKMARK} className={classes.bookMark} />
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={6} item container className={classes.imgWrapper}>
          <img
            alt="pic"
            src={POPULARBLOG1}
            onClick={gotoBlog}
            className={classes.imgBlg}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PopularBlog6;
