import React from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";

import worksBKG from "../../../assets/images/worksBKG.svg";
import AnswerImg from "../../../assets/images/AnswerImg.svg";
import MOBILE_ARROW from "../../../assets/images/MOBILE_ARROW.svg";
import FillImg from "../../../assets/images/FillImg.svg";
import GetMailImg from "../../../assets/images/GetMailImg.svg";
import { ROCKWELL_REG } from "../../../GlobalConstants";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: 40,
  },
  root1: {
    backgroundImage: `url(${worksBKG})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    paddingBottom: "10%",
    paddingLeft: "10%",
    paddingRight: "10%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0%",
      paddingRight: "0%",
    },
  },
  txt1Wrapper: {
    justifyContent: "center",
    paddingTop: "6%",
    paddingBottom: "6%",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "15%",
    },
  },
  txt1: {
    fontFamily: ROCKWELL_REG,
    fontSize: 56,
    color: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
    },
  },
  imgTxtWrapper: {
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "15%",
    },
  },
  imgTxtWrapper1: {
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "15%",
    },

    [theme.breakpoints.up("md")]: {
      borderLeftStyle: "solid",
      borderRightStyle: "solid",
      borderLeftWidth: 1.5,
      borderRightWidth: 1.5,
      borderLeftColor: "#e4e4e4",
      borderRightColor: "#e4e4e4",
    },
  },
  imgWrapper: {
    [theme.breakpoints.up("md")]: {
      paddingBottom: "6%",
    },
  },
  tx2: {
    fontSize: 16,
    fontFamily: ROCKWELL_REG,
    color: theme.palette.primary.dark,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobtxtWrapper: {
    alignContent: "center",
    paddingLeft: "10%",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  tx2_1: {
    fontSize: 16,
    fontFamily: ROCKWELL_REG,
    color: theme.palette.primary.dark,
    textAlign: "left",
  },
  img: {
    [theme.breakpoints.down("sm")]: {
      width: 121,
      height: 121,
    },
  },
  arrow1: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    position: "absolute",
    paddingTop: "30%",
    paddingLeft: "10%",
  },
}));

const HowWorks = (props) => {
  const classes = useStyles();

  return (
    <Grid container justify="center" className={classes.root}>
      <Grid container justify="center" className={classes.root1}>
        <Grid xs={12} item container className={classes.txt1Wrapper}>
          <Typography className={classes.txt1}>How It Works?</Typography>
        </Grid>
        <Grid md={4} xs={12} className={classes.imgTxtWrapper} item container>
          <Grid
            xs={4}
            md={12}
            item
            container
            justify="center"
            className={classes.imgWrapper}
          >
            <img alt="answer" src={AnswerImg} className={classes.img} />
          </Grid>

          <Grid xs={8} item container className={classes.mobtxtWrapper}>
            <Grid>
              <Typography className={classes.tx2_1}>Answer 4</Typography>
              <Typography className={classes.tx2_1}>
                Quick questions.
              </Typography>
            </Grid>
            <img alt="arrow" src={MOBILE_ARROW} className={classes.arrow1} />
          </Grid>
          <Grid md={12} item container justify="center">
            <Typography className={classes.tx2}>Answer 4</Typography>
          </Grid>
          <Grid xs={12} item container justify="center">
            <Typography className={classes.tx2}>Quick questions.</Typography>
          </Grid>
        </Grid>
        <Grid md={4} xs={12} className={classes.imgTxtWrapper1} item container>
          <Grid
            xs={4}
            md={12}
            item
            container
            justify="center"
            className={classes.imgWrapper}
          >
            <img alt="fill" src={FillImg} className={classes.img} />
          </Grid>
          <Grid xs={8} item container className={classes.mobtxtWrapper}>
            <Grid>
              <Typography className={classes.tx2_1}>Fill in your</Typography>
              <Typography className={classes.tx2_1}>Email Address.</Typography>
            </Grid>
            <img alt="arrow" src={MOBILE_ARROW} className={classes.arrow1} />
          </Grid>
          <Grid xs={12} item container justify="center">
            <Typography className={classes.tx2}>Fill in your</Typography>
          </Grid>
          <Grid xs={12} item container justify="center">
            <Typography className={classes.tx2}>Email Address.</Typography>
          </Grid>
        </Grid>
        <Grid md={4} xs={12} className={classes.imgTxtWrapper} item container>
          <Grid
            md={12}
            xs={4}
            item
            container
            justify="center"
            className={classes.imgWrapper}
          >
            <img alt="getmail" src={GetMailImg} className={classes.img} />
          </Grid>
          <Grid xs={8} item container className={classes.mobtxtWrapper}>
            <Grid>
              <Typography className={classes.tx2_1}>
                Get a customised free
              </Typography>
              <Typography className={classes.tx2_1}>
                game for your pet on
              </Typography>
              <Typography className={classes.tx2_1}>
                your Email Address.
              </Typography>
            </Grid>
          </Grid>
          <Grid xs={12} item container justify="center">
            <Typography className={classes.tx2}>
              Get a customised free
            </Typography>
          </Grid>
          <Grid xs={12} item container justify="center">
            <Typography className={classes.tx2}>
              game for your pet on
            </Typography>
          </Grid>
          <Grid xs={12} item container justify="center">
            <Typography className={classes.tx2}>your Email Address.</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HowWorks;
