import React from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import BlogDetailIMG1 from "../../assets/images/BlogDetailIMG3.jpg";
import BlogDetailIMG2 from "../../assets/images/BlogDetailIMG2.svg";
import BlogDetailIMG3 from "../../assets/images/BlogDetailIMG3.svg";

import { ROCKWELL_REG, QUICKSAND_REG } from "../../GlobalConstants";
import Footer from "../statics/Footer";
import Blog3More from "./components/Blog3More";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 76,
    justifyContent: "flex-start",
    paddingLeft: "6%",
    [theme.breakpoints.down("sm")]: {
      marginTop: 61,
      justifyContent: "center",
      paddingLeft: "0",
    },
  },
  img: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },
  grid1: {
    [theme.breakpoints.up("md")]: { marginLeft: "15%" },
  },
  txt1Wrapper: {
    paddingTop: "4%",
    paddingBottom: "4%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "2%",
    },
  },
  txt1: {
    fontFamily: ROCKWELL_REG,
    color: theme.palette.primary.dark,
    fontSize: 30,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  txt1_1: {
    fontFamily: ROCKWELL_REG,
    color: theme.palette.primary.dark,
    fontSize: 22,
    textAlign: "left",
    maxWidth: "70%",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  txt2Wrapper: {
    //paddingBottom: "4%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "2%",
    },
  },
  txt2Wrapper1: {
    paddingTop: "3%",
    paddingBottom: "3%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "2%",
    },
  },
  txt2: {
    fontFamily: QUICKSAND_REG,
    color: theme.palette.primary.dark,
    fontSize: 16,
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
      maxWidth: "90%",
    },
  },
  txt3Wrapper: {
    paddingBottom: "4%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "2%",
      paddingTop: "3%",
    },
  },
  txt3: {
    fontFamily: QUICKSAND_REG,
    color: theme.palette.primary.dark,
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "left",
  },
  img2Wrapper: {
    paddingTop: "3%",
  },
  txt4Wrapper: {
    paddingTop: "2%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "2%",
    },
  },
  txt4: {
    fontFamily: ROCKWELL_REG,
    color: theme.palette.primary.dark,
    fontSize: 25,
    textAlign: "left",
  },
  ul: {
    color: theme.palette.primary.dark,
    padding: 0,
    margin: 0,
    paddingLeft: "3%",
  },
}));

const Blog3Detail = React.forwardRef((props, ref) => {
  const classes = useStyles();

  return (
    <div>
      <Grid container className={classes.root} justify="center">
        <Grid container className={classes.grid1} xs={12} md={5} item>
          <Grid item xs={12} className={classes.img1Wrapper}>
            <img alt="img1" src={BlogDetailIMG1} className={classes.img} />
          </Grid>
          <Grid xs={12} item container className={classes.txt1Wrapper}>
            <Typography className={classes.txt1}>
              Know About: Symptoms Of Worms In Dogs
            </Typography>
            <Typography className={classes.txt1_1}>
              Know About: Symptoms Of Worms In Dogs
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Some of these worms that usually attack dogs are hookworms,
              tapeworms, whipworms, roundworms, and heartworms. Several kinds of
              infections are more obvious to detect than others. For instance,
              if your pooch hits up a tapeworm, it’s simple to understand what
              matches grains of rice in its stool. Heartworms, on the opposite
              side, are more difficult to diagnose and an infected dog will
              usually provide only complex symptoms until the infection has
              advanced to a further stage.
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.img2Wrapper}>
            <img alt="img2" src={BlogDetailIMG2} className={classes.img} />
          </Grid>
          <Grid xs={12} item container className={classes.txt4Wrapper}>
            <Typography className={classes.txt4}>
              These are the 11 most typical symptoms of worms in dogs:
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt4Wrapper}>
            <Typography className={classes.txt4}>Coughing</Typography>
          </Grid>

          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              One of the first symptoms in a dog of heartworms is coughing. Dogs
              with hookworms and roundworms may also catch a cough.
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.img2Wrapper}>
            <img alt="img3" src={BlogDetailIMG3} className={classes.img} />
          </Grid>
          <Grid xs={12} item container className={classes.txt4Wrapper}>
            <Typography className={classes.txt4}>Diarrhea</Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Pale stools and dog diarrhea can be the result of infections. More
              than diarrhea, you might also be able to see blood in the dog's
              stools due to hookworms.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt4Wrapper}>
            <Typography className={classes.txt4}>Dull coat</Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              A normal dog must have a bright solid coat. If your dog’s coat
              starts to dull and dry out, he may have got worms. Hair loss or
              the presence of rashes can also indicate infections.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt4Wrapper}>
            <Typography className={classes.txt4}>
              Rashes and symptoms of skin irritations
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Dogs that show hints of skin irritation may have a critical
              presence of worms.
            </Typography>
          </Grid>

          <Grid xs={12} item container className={classes.txt4Wrapper}>
            <Typography className={classes.txt4}>
              Rubbing its bottom on the ground or “rushing”
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Though this can usually be due to the problems with the anal
              glands, dogs with infections will irregularly rub their rear ends
              on the ground to make themselves relieved of the itch because of
              the worms present in the area.
            </Typography>
          </Grid>

          <Grid xs={12} item container className={classes.txt4Wrapper}>
            <Typography className={classes.txt4}>Low energy</Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Dogs that are lazy and less productive than usual may be
              exhibiting signs of holding worms.{" "}
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt4Wrapper}>
            <Typography className={classes.txt4}>
              Visible worms in fur or fecal thing
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Few worms, such as tapeworms may seem to be little moving segments
              in the fur or area nearby dog’s anus. Roundworms can usually be
              detected in a dog’s stools.
            </Typography>
          </Grid>

          <Grid xs={12} item container className={classes.txt4Wrapper}>
            <Typography className={classes.txt4}>Weight loss</Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              If your dog reveals any signs of fast weight loss, he might be
              having a tapeworm or a whipworm.
            </Typography>
          </Grid>

          <Grid xs={12} item container className={classes.txt4Wrapper}>
            <Typography className={classes.txt4}>Bloated Look</Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              If your dog starts to seem bloated, it may have shrunk worms. This
              is usually observed in puppies that have got the worms from their
              mother.
            </Typography>
          </Grid>

          <Grid xs={12} item container className={classes.txt4Wrapper}>
            <Typography className={classes.txt4}>
              Variation in appetite
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              If you see any unexpected change in your dog’s hunger, he may have
              got into touch with roundworms. Dogs that are affected usually
              miss their appetite. As worms weaken a dog’s nutrients, a dog with
              infections may also tell a swift rise in hunger.
            </Typography>
          </Grid>

          <Grid xs={12} item container className={classes.txt4Wrapper}>
            <Typography className={classes.txt4}>Vomiting</Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Dogs that have worms will usually throw up. Roundworms might be
              seen in a dog’s vomit.
            </Typography>
          </Grid>

          <Grid xs={12} item container className={classes.txt4Wrapper}>
            <Typography className={classes.txt4}>
              If you find any worms in your dog
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              If neglected, worms can harm your dog’s inner organs and begin to
              lack consciousness which may lead to death. If you speculate that
              your dog has any sort of worms or infections, immediately take it
              to your nearest veterinary facility. Most internal worms are
              easily treatable and your vet will give your dog a medicine based
              on the diagnosis. Heartworm disease, though, can be costly and
              stressful for your dog, so the most suitable therapy is the
              administration of a monthly preventive medicine (which can further
              restrict other worm diseases). Discuss with your vet for more
              advice for your dog.
            </Typography>
          </Grid>
        </Grid>

        <Grid container className={classes.grid2}></Grid>

        <Grid container className={classes.grid3}></Grid>

        <Blog3More {...props} />
      </Grid>
      <Footer />
    </div>
  );
});

export default Blog3Detail;
