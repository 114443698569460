import React from "react";
import { makeStyles, Grid, Typography, Paper, Button } from "@material-ui/core";
import { ROCKWELL_REG, QUICKSAND_REG, INTER } from "../../GlobalConstants";
import BUTTON_ARROW from "../../assets/images/BUTTON_ARROW.svg";
import Footer from "../statics/Footer";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 130,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 80,
    },
  },
  headingTxtWrapper: {},
  headingTxt: {
    fontSize: 40,
    color: theme.palette.primary.dark,
    fontFamily: ROCKWELL_REG,
    [theme.breakpoints.down("sm")]: {
      fontSize: 22,
      width: 328,
      textAlign: "center",
    },
  },
  paper: {
    width: "70%",
    marginLeft: "5%",
    marginTop: "5%",
    overflow: "hidden",
    borderRadius: 20,
    boxShadow:
      " 0px 1px 3px 0px rgba(0,0,0,0.12),  0px 1px 3px 0px rgba(0,0,0,0.12), 0px 1px 23px 0px rgba(0,0,0,0.12)",
    [theme.breakpoints.down("sm")]: {
      width: 314,
      marginLeft: "0%",
      paddingBottom: "8%",
    },
    marginBottom: "7%",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "25%",
    },
  },
  video: {
    width: 560,
    height: 315,
    [theme.breakpoints.down("sm")]: {
      width: 314,
      height: 176,
    },
  },
  txt2Wrapper: {
    padding: "3%",
    maxWidth: "38%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "95%",
      paddingLeft: "8%",
      paddingTop: "8%",
    },
  },
  txt2: {
    textAlign: "left",
    fontSize: 22,
    fontFamily: QUICKSAND_REG,
    color: theme.palette.primary.dark,
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
      lineHeight: 1.5,
    },
  },
  btn: {
    backgroundColor: theme.palette.primary.main,
    height: 60,
    paddingLeft: "13%",
    paddingRight: "10%",
    [theme.breakpoints.down("sm")]: {
      width: 159,
      height: 36,
      paddingLeft: "0%",
      paddingRight: "0%",
      marginTop: "5%",
    },
  },
  btnTxt: {
    fontFamily: INTER,
    fontSize: 26,
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
}));

const Video3 = (props) => {
  const classes = useStyles();

  return (
    <Grid container justify="center" className={classes.root}>
      <Grid
        xs={12}
        item
        container
        justify="center"
        className={classes.headingTxtWrapper}
      >
        <Typography className={classes.headingTxt}>
          See how Adrienne cured Maggie’s jumping problem.
        </Typography>
      </Grid>
      <Paper className={classes.paper}>
        <Grid container>
          <Grid xs={12} md={7} item container>
            <iframe
              className={classes.video}
              src="https://www.youtube.com/embed/xjsFFXuwO-8"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Grid>
          <Grid md={5} xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Adrienne’s methods are force-free and gentle. They rely on the
              latest science in dog behaviour research to create a strong bond
              between you and your dog and create positive emotions in your dog
              as opposed to fearful ones. They only reinforce the behaviours you
              want.
            </Typography>
            <Button
              className={classes.btn}
              target="_blank"
              href="https://8826ecpqwkvr2nez22sd36sb2x.hop.clickbank.net/?tid=FF"
              endIcon={
                <Grid className={classes.btnImg} container justify="center">
                  <img alt="arrow" src={BUTTON_ARROW} />
                </Grid>
              }
            >
              <Typography className={classes.btnTxt}>Learn More </Typography>
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Footer />
    </Grid>
  );
};

export default Video3;
