import React from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import FOOTER_LOGO from "../../assets/images/FOOTER_LOGO.svg";
import { QUICKSAND_REG } from "../../GlobalConstants";
import { NavLink } from "react-router-dom";
import FB from "../../assets/images/FB.svg";
import INSTA from "../../assets/images/INSTA.svg";
import WHATSAPP from "../../assets/images/WHATSAPP.svg";
import YUOUTUBE from "../../assets/images/YUOUTUBE.svg";
import FOOTERICON from "../../assets/images/FOOTERICON.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "7%",
    paddingBottom: "7%",
    paddingLeft: "17%",
    paddingRight: "17%",

    backgroundColor: theme.palette.secondary.dark,
    [theme.breakpoints.down("sm")]: {
      paddingTop: "20%",
      paddingLeft: "10%",
      paddingRight: "10%",
      paddingBottom: "20%",
    },
  },
  logoWrapper: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      paddingBottom: "5%",
    },
  },
  logo: {
    [theme.breakpoints.down("sm")]: {
      width: 174,
      height: 28,
      marginBottom: "6%",
    },
  },

  text1: {
    color: theme.palette.text.secondary,
    fontSize: 15,
    fontWeight: "bold",
    fontFamily: QUICKSAND_REG,
    marginTop: "5%",
    marginBottom: "5%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  text2: {
    color: theme.palette.text.secondary,
    fontSize: 15,
    fontFamily: QUICKSAND_REG,
    marginBottom: "2%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  text2_1: {
    color: theme.palette.text.secondary,
    fontSize: 15,
    fontFamily: QUICKSAND_REG,
    marginBottom: "4%",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  rightWrapper: {
    paddingLeft: "32%",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      paddingLeft: 0,
    },
  },
  rightTextWrapper: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },

  rightText: {
    textDecoration: "none",
    color: theme.palette.text.secondary,
    fontSize: 15,
    fontWeight: "bold",
    fontFamily: QUICKSAND_REG,
    marginTop: "5%",
    marginBottom: "5%",
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
  },
  socialWrapper: {
    paddingTop: "4%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  socialWrapper1: {
    paddingTop: "7%",

    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  copyRightSMWrapper: {
    paddingTop: "8%",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  icon: {
    position: "absolute",
    right: 25,
    bottom: "95%",
    [theme.breakpoints.down("sm")]: {
      bottom: "98%",
      right: 20,
    },
  },
  footerIcon: {
    [theme.breakpoints.down("sm")]: {
      width: 118,
      height: 62,
    },
  },
}));
const Footer = React.forwardRef((props, ref) => {
  const classes = useStyles();

  return (
    <div style={{ position: "relative" }}>
      <div className={classes.icon}>
        <img alt="footer" src={FOOTERICON} className={classes.footerIcon} />
      </div>
      <Grid container justify="center" className={classes.root}>
        <Grid md={6} xs={12} container item className={classes.logoWrapper}>
          <img alt="footerLogo" src={FOOTER_LOGO} className={classes.logo} />
          <Typography className={classes.text1}>
            Copyright © 2019 DIY PET STORE. All Rights Reserved.{" "}
          </Typography>
          <Typography className={classes.text2}>
            This site is not a part of the Facebook website or Facebook Inc.
          </Typography>
          <Typography className={classes.text2}>
            Additionally, this site is NOT endorsed by Facebook in any way.
          </Typography>
          <Typography className={classes.text2}>
            FACEBOOK is a trademark of FACEBOOK, Inc.
          </Typography>
          <Typography className={classes.text2_1}>
            This site is not a part of the Facebook
          </Typography>
          <Typography className={classes.text2_1}>
            website or Facebook Inc. Additionally, this{" "}
          </Typography>
          <Typography className={classes.text2_1}>
            site is NOT endorsed by Facebook in any
          </Typography>
          <Typography className={classes.text2_1}>
            way. FACEBOOK is a trademark of
          </Typography>

          <Typography className={classes.text2_1}>FACEBOOK, Inc. </Typography>

          <Grid container className={classes.socialWrapper}>
            {/*  <Grid container justify="center" item xs={1} alignContent="center">
              <NavLink to="/">
                <img alt="whatsApp" src={WHATSAPP} />
              </NavLink>
            </Grid> */}
            {/* <Grid container justify="center" xs={1} item alignContent="center">
              <NavLink to="/">
                <img alt="utube" src={YUOUTUBE} />
              </NavLink>
            </Grid> */}
            <Grid container justify="center" xs={1} item alignContent="center">
              <NavLink to="/">
                <img alt="Fb" src={FB} />
              </NavLink>
            </Grid>
            <Grid container justify="center" xs={1} item alignContent="center">
              <NavLink to="/">
                <img alt="insta" src={INSTA} />
              </NavLink>
            </Grid>
          </Grid>
        </Grid>
        <Grid md={6} container item className={classes.rightWrapper}>
          <Grid item xs={12} container className={classes.rightTextWrapper}>
            <NavLink to="/privPolicy" className={classes.rightText}>
              Privacy Policy
            </NavLink>
          </Grid>
          <Grid item xs={12} container className={classes.rightTextWrapper}>
            <NavLink to="/tnc" className={classes.rightText}>
              {`Terms & Conditions`}
            </NavLink>
          </Grid>
          {/* <Grid item xs={12} container className={classes.rightTextWrapper}>
            <Typography className={classes.rightText}>Anti-Spam</Typography>
          </Grid> */}

          <Grid item xs={12} container className={classes.rightTextWrapper}>
            {" "}
            <NavLink to="/contact" className={classes.rightText}>
              Contact Us{" "}
            </NavLink>
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={12}
          justify="center"
          className={classes.socialWrapper1}
        >
          {/* <Grid container justify="center" item xs={2} alignContent="center">
            <NavLink to="/">
              <img alt="whatsApp" src={WHATSAPP} />
            </NavLink>
          </Grid> */}
          <Grid container justify="center" xs={2} item alignContent="center">
            <NavLink to="/">
              <img alt="utube" src={YUOUTUBE} />
            </NavLink>
          </Grid>
          {/*  <Grid container justify="center" xs={2} item alignContent="center">
            <NavLink to="/">
              <img alt="Fb" src={FB} />
            </NavLink>
          </Grid> */}
          <Grid container justify="center" xs={2} item alignContent="center">
            <NavLink to="/">
              <img alt="insta" src={INSTA} />
            </NavLink>
          </Grid>
        </Grid>

        <Grid
          container
          justify="center"
          container
          className={classes.copyRightSMWrapper}
        >
          <Grid xs={12} item justify="center" container>
            <Typography className={classes.text2_1}>
              Copyright © 2019 DIY PET STORE.
            </Typography>
          </Grid>
          <Grid xs={12} justify="center" item container>
            <Typography className={classes.text2_1}>
              All Rights Reserved.{" "}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
});

export default Footer;
