import React from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import BlogDetailIMG1 from "../../assets/images/BlogDetailIMG1.svg";
import BlogDetailIMG2 from "../../assets/images/BlogDetailIMG2.svg";
import BlogDetailIMG3 from "../../assets/images/BlogDetailIMG3.svg";

import { ROCKWELL_REG, QUICKSAND_REG } from "../../GlobalConstants";
import Footer from "../statics/Footer";
import Blog10More from "./components/Blog10More";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 76,
    justifyContent: "flex-start",
    paddingLeft: "6%",
    [theme.breakpoints.down("sm")]: {
      marginTop: 61,
      justifyContent: "center",
      paddingLeft: "0",
    },
  },
  img: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },
  grid1: {
    [theme.breakpoints.up("md")]: { marginLeft: "15%" },
  },
  txt1Wrapper: {
    paddingTop: "4%",
    paddingBottom: "4%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "2%",
    },
  },
  txt1: {
    fontFamily: ROCKWELL_REG,
    color: theme.palette.primary.dark,
    fontSize: 30,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  txt1_1: {
    fontFamily: ROCKWELL_REG,
    color: theme.palette.primary.dark,
    fontSize: 22,
    textAlign: "left",
    maxWidth: "70%",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  txt2Wrapper: {
    //paddingBottom: "4%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "2%",
    },
  },
  txt2Wrapper1: {
    paddingTop: "3%",
    paddingBottom: "3%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "2%",
    },
  },
  txt2: {
    fontFamily: QUICKSAND_REG,
    color: theme.palette.primary.dark,
    fontSize: 16,
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
      maxWidth: "90%",
    },
  },
  txt3Wrapper: {
    paddingBottom: "4%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "2%",
      paddingTop: "3%",
    },
  },
  txt3: {
    fontFamily: QUICKSAND_REG,
    color: theme.palette.primary.dark,
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "left",
  },
  img2Wrapper: {
    paddingTop: "3%",
  },
  txt4Wrapper: {
    paddingTop: "2%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "2%",
    },
  },
  txt4: {
    fontFamily: ROCKWELL_REG,
    color: theme.palette.primary.dark,
    fontSize: 25,
    textAlign: "left",
  },
  ul: {
    color: theme.palette.primary.dark,
    padding: 0,
    margin: 0,
    paddingLeft: "3%",
  },
}));

const Blog10Detail = React.forwardRef((props, ref) => {
  const classes = useStyles();

  return (
    <div>
      <Grid container className={classes.root} justify="center">
        <Grid container className={classes.grid1} xs={12} md={5} item>
          <Grid item xs={12} className={classes.img1Wrapper}>
            <img alt="img1" src={BlogDetailIMG1} className={classes.img} />
          </Grid>
          <Grid xs={12} item container className={classes.txt1Wrapper}>
            <Typography className={classes.txt1}>
              Know how to Stop Your Dog’s Continuous Barking
            </Typography>
            <Typography className={classes.txt1_1}>
              Know how to Stop Your Dog’s Continuous Barking
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              It might be getting difficult for you to handle your dog's
              barking. The moment you leave your house, it gets worse. Have you
              observed this? Have your neighbours starting getting pissed off?
              Don't you have this thought of getting rid of your dog anytime
              during this? Go easy! Don't get so anxious. We have a solution for
              this problem.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              You can stop the unnecessary barking of your dog with the use of
              bark collars. Using the electronic bark collar properly for a few
              days will completely stop the dog barking. Many electronic bark
              control collars operate sound as the primary stimulus as we know
              that the animals grasp faster and have a better memory when a
              particular behaviour is linked with a sound.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt3Wrapper}>
            <Typography className={classes.txt3}>
              The more ahead models progressively enhance the sonic stimulus as
              barking continues. Many models operate progressively developing
              electric shock stimulus accompanying with the sound to prevent
              even the most obstinate barkers gently. The more advanced
              electronic bark collars sense the vibrations in a dog’s throat
              that occur only during barking. By performing so, they prevent
              malicious changes from other loud noises and other dogs barking.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt3Wrapper}>
            <Typography className={classes.txt3}>
              The disadvantage with various “barking dog collars” is that they
              might be detecting barking with a microphone and initiate on
              sound; those can be set off by different noises or by different
              dogs bark. Few Barking Dog Collars simply use vibration; these
              might be a set off by movement or pushing.
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.img2Wrapper}>
            <img alt="img2" src={BlogDetailIMG2} className={classes.img} />
          </Grid>
          <Grid xs={12} item container className={classes.txt4Wrapper}>
            <Typography className={classes.txt4}>
              Be aware of the following types of bark collars:
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              1) Bark Dog Collars the project a spray mist which frightens and
              upsets the dog’s olfactory senses, making him prevent barking.
              These sprays may lead to allergic effects and hypersensitivity in
              your dog.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              2) Unconventional tools that have a built-in microphone harmonized
              to the sound of your dog’s bark. They utter a heavy correction to
              frighten the dog so he ends barking. They automatically end and
              reset themselves after every hearing correction. The dog assumes
              no improvement with this type of bark stopper and can directly
              move away to different locations besides the receiver and dodge
              the noise completely.
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.img2Wrapper}>
            <img alt="img3" src={BlogDetailIMG3} className={classes.img} />
          </Grid>
          <Grid xs={12} item container className={classes.txt4Wrapper}>
            <Typography className={classes.txt4}>
              Search for a barking dog collar with the following features:
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              1) One that together senses both vibration and sound, and simply
              uses a blend of sound and vibration to trigger the electronic
              revision. By holding both of these traits, the electronic bark
              collar will be initiated just when the dog wears the collar, so
              there won’t be any “wrong corrections” created by heavy sounds or
              another dog barking.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              2) One that automatically changes the level of static emendation
              to your dog’s mood with varying levels of self-adjustable changes
              in acknowledgment of the barking strength of your dog. The more
              intensity settings will assist to provide the needed sensitivity
              to the most obstinate dogs.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              3) One that gives a signal beep with the initial bark.
            </Typography>
          </Grid>

          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              4) One with a rechargeable battery, which highlights a low battery
              sign light.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              5) One that highlights a quick-fit clamp that gives a fast
              discharge.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              6) One that is long-lasting, lightweight, insulated, and
              adaptable.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              7) One that arrives with a whole running design and a recording
              showing how to use.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              We hope you all the happiness in the world with your little furry
              pooch and you achieve success in managing the barking.
            </Typography>
          </Grid>
        </Grid>

        <Grid container className={classes.grid2}></Grid>

        <Grid container className={classes.grid3}></Grid>

        <Blog10More {...props} />
      </Grid>
      <Footer />
    </div>
  );
});

export default Blog10Detail;
