import React from "react";
import { makeStyles, Grid, Typography, Paper, Button } from "@material-ui/core";
import { ROCKWELL_REG, QUICKSAND_REG, INTER } from "../../../GlobalConstants";
import BUTTON_ARROW from "../../../assets/images/BUTTON_ARROW.svg";
import CheckIcon from "@material-ui/icons/Check";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: "6%",
    paddingRight: "6%",
    paddingTop: "7%",
    paddingBottom: "7%",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "25%",
    },
  },
  headingTxtWrapper: {
    paddingBottom: "4%",
  },
  headingTxt: {
    fontSize: 40,
    color: theme.palette.primary.dark,
    fontFamily: ROCKWELL_REG,
    minWidth: "100%",
    marginBottom: "4%",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: 22,
      width: 328,
      textAlign: "left",
    },
  },
  subTxt: {
    fontSize: 20,
    fontFamily: ROCKWELL_REG,
    textAlign: "left",
    marginBottom: "4%",
    color: theme.palette.text.primary,
  },
}));

const VideoScreen = (props) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Grid xs={12} md={6} item container className={classes.headingTxtWrapper}>
        {/*  <Typography className={classes.headingTxt}>
          Here's what You're Going To Get!
        </Typography>
        <Typography className={classes.subTxt}>
          <CheckIcon />
          {`  It is the foundation for the whole course`}
        </Typography>
        <Typography className={classes.subTxt}>
          <CheckIcon />
          {`  Get 7 trick training videos including`}
          <ul>
            <li>Take a Bow</li>
            <li>Cover his Eyes (‘Shame on You’)</li>
            <li>Dance</li>
            <li>Shake hands</li>
            <li>Howl</li>
            <li>Play dead</li>
            <li>Roll over</li>
          </ul>
        </Typography> */}
      </Grid>
    </Grid>
  );
};

export default VideoScreen;
