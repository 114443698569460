import React from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import BlogDetailIMG1 from "../../assets/images/BlogDetailIMG2.jpg";
import BlogDetailIMG2 from "../../assets/images/BlogDetailIMG2.svg";
import BlogDetailIMG3 from "../../assets/images/BlogDetailIMG3.svg";

import { ROCKWELL_REG, QUICKSAND_REG } from "../../GlobalConstants";
import Footer from "../statics/Footer";
import Blog2More from "./components/Blog2More";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 76,
    justifyContent: "flex-start",
    paddingLeft: "6%",
    [theme.breakpoints.down("sm")]: {
      marginTop: 61,
      justifyContent: "center",
      paddingLeft: "0",
    },
  },
  img: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },
  grid1: {
    [theme.breakpoints.up("md")]: { marginLeft: "15%" },
  },
  txt1Wrapper: {
    paddingTop: "4%",
    paddingBottom: "4%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "2%",
    },
  },
  txt1: {
    fontFamily: ROCKWELL_REG,
    color: theme.palette.primary.dark,
    fontSize: 30,
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  txt1_1: {
    fontFamily: ROCKWELL_REG,
    color: theme.palette.primary.dark,
    fontSize: 22,
    textAlign: "left",
    maxWidth: "70%",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  txt2Wrapper: {
    //paddingBottom: "4%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "2%",
    },
  },
  txt2Wrapper1: {
    paddingTop: "3%",
    paddingBottom: "3%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "2%",
    },
  },
  txt2: {
    fontFamily: QUICKSAND_REG,
    color: theme.palette.primary.dark,
    fontSize: 16,
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
      maxWidth: "90%",
    },
  },
  txt3Wrapper: {
    paddingBottom: "4%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "2%",
      paddingTop: "3%",
    },
  },
  txt3: {
    fontFamily: QUICKSAND_REG,
    color: theme.palette.primary.dark,
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "left",
  },
  img2Wrapper: {
    paddingTop: "3%",
  },
  txt4Wrapper: {
    paddingTop: "2%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "2%",
    },
  },
  txt4: {
    fontFamily: ROCKWELL_REG,
    color: theme.palette.primary.dark,
    fontSize: 25,
    textAlign: "left",
  },
  ul: {
    color: theme.palette.primary.dark,
    padding: 0,
    margin: 0,
    paddingLeft: "3%",
  },
}));

const Blog2Detail = React.forwardRef((props, ref) => {
  const classes = useStyles();

  return (
    <div>
      <Grid container className={classes.root} justify="center">
        <Grid container className={classes.grid1} xs={12} md={5} item>
          <Grid item xs={12} className={classes.img1Wrapper}>
            <img alt="img1" src={BlogDetailIMG1} className={classes.img} />
          </Grid>
          <Grid xs={12} item container className={classes.txt1Wrapper}>
            <Typography className={classes.txt1}>
              {`How To Keep You & Your Dog Relaxed During Covid19 Pandemic`}
            </Typography>
            <Typography className={classes.txt1_1}>
              {`How To Keep You & Your Dog Relaxed During Covid19 Pandemic`}
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              We all know how things are getting out of hand these days due to
              the covid19 pandemic. Everyone is pretty devasted because of the
              current situation. It has impacted people not only on a physical
              level but mental level too. The only thing which we can do is
              taking corrective measures and precautions to keep ourselves and
              our loved ones safe from this pandemic. People who own a pet are
              also afraid and cannot decide what all they must be doing to not
              make their dog feel left out and depressed.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              So, here we have jotted down a few tips which will help you and
              your pet to stay happy and relaxed during these difficult times.
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.img2Wrapper}>
            <img alt="img2" src={BlogDetailIMG2} className={classes.img} />
          </Grid>
          <Grid xs={12} item container className={classes.txt4Wrapper}>
            <Typography className={classes.txt4}>
              Take out some time to play with your little friend
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              We all are busy with so many things these days. From doing
              household chores to making food, taking care of our children and
              family members. Everything must be so stressful. Although, during
              these difficult times, you must try and manage your work schedule
              and take out some time to play with your little furry friend which
              will not only make him feel special and happy but will also make
              you feel relaxed.
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              As these days we can’t take our dogs outside the home premises, so
              it’s better to play with them indoors with their toys. You can run
              out to your backyard and if you have kids then you can also
              involve them to play with your dog. This will not only make you
              relaxed but also make your kids feel happy and energetic.
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.img2Wrapper}>
            <img alt="img3" src={BlogDetailIMG3} className={classes.img} />
          </Grid>
          <Grid xs={12} item container className={classes.txt4Wrapper}>
            <Typography className={classes.txt4}>
              Give them some special treat!
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              We all have been longing for outside food. We missed eating out
              with our friends, going to restaurants having our favourite meals.
              However, these days people are quite experimenting at home and
              cooking their favourite meals. This seems to be really exciting
              and also enjoyable for the family. Meanwhile, don’t forget your
              dog who is also missing his regular outings and yummy food. So, do
              make sure to serve him some yummy treats which he would love to
              enjoy! After all, he can’t prepare his meal as we do!
            </Typography>
          </Grid>

          <Grid xs={12} item container className={classes.txt4Wrapper}>
            <Typography className={classes.txt4}>
              Why don’t we arrange a fun-loving bath for him?
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Dogs love to get wet and they enjoy the water. In this scorching
              weather, dogs start to feel hot and also a little low-spirited.
              So, to make them feel energized a good cold bath would be an
              excellent idea. You can arrange a bathtub in your backyard and
              allow your dog to dive into it, making him feel relaxed. Trust me,
              he will love you for this!
            </Typography>
          </Grid>

          <Grid xs={12} item container className={classes.txt4Wrapper}>
            <Typography className={classes.txt4}>
              Take a nap together
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Who doesn’t love to nap? We all have been home for months and we
              all feel pretty tired after doing so much work all day. In between
              the day, we must lie down for a while and take a good, soothing
              nap with our little friend and relax a bit more. This will make
              you feel refreshed once you wake up and also make your tiredness
              go away!
            </Typography>
          </Grid>

          {/** leave it */}
          <Grid xs={12} item container className={classes.txt4Wrapper}>
            <Typography className={classes.txt4}>
              Make him feel loved
            </Typography>
          </Grid>
          <Grid xs={12} item container className={classes.txt2Wrapper}>
            <Typography className={classes.txt2}>
              Dogs are the most loyal friend of humans. They love us more than
              we love them. They will love you no matter how you behave with
              them. They love you unconditionally without any expectations.
              Although, this pandemic might be making you feel so afraid, or in
              doubt or fear, but petting your dog for a while is the way to make
              him feel safe and comfortable.
            </Typography>
          </Grid>

          <Grid xs={12} item container className={classes.txt2Wrapper1}>
            <Typography className={classes.txt2}>
              Get rid of some stress of yours and his. Despite the current
              situation, you must feel lucky that you have a furry friend by
              your side who loves you so much and you should love him back more.
              After all, he only has you by his side!
            </Typography>
          </Grid>
        </Grid>

        <Grid container className={classes.grid2}></Grid>

        <Grid container className={classes.grid3}></Grid>

        <Blog2More {...props} />
      </Grid>
      <Footer />
    </div>
  );
});

export default Blog2Detail;
