import React from "react";
import AppBar from "@material-ui/core/AppBar";
import { NavLink, withRouter } from "react-router-dom";
import {
  makeStyles,
  Toolbar,
  Typography,
  Grid,
  ListItem,
  ListItemIcon,
} from "@material-ui/core";
import clsx from "clsx";

import LOGO from "../../assets/images/LOGO.svg";
import CART from "../../assets/images/CART.svg";
import LeftMenuSm from "../../assets/images/LeftMenuSm.svg";
import { ROCKWELL_REG } from "../../GlobalConstants";

import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  root: {
    height: 60,
    flexGrow: 1,
    overflow: "hidden",

    backgroundColor: theme.palette.primary.light,
    [theme.breakpoints.down("sm")]: {
      boxShadow: "0 10px 10px -10px #000000",
      borderBottomWidth: 1,
      borderBottomStyle: "solid",
      //borderBottomColor: "black",
    },
    [theme.breakpoints.up("md")]: {
      height: 100,
      paddingLeft: "5%",
      paddingRight: "5%",
      paddingTop: 10,
      // boxShadow: "none",
      backgroundColor: theme.palette.primary.light,
    },
  },
  grow: {
    flexGrow: 1,
  },
  ProfileSm: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  logo: { [theme.breakpoints.down("sm")]: { height: 24.48, width: 151 } },

  whiteBTN: {
    backgroundColor: theme.palette.secondary.main,
    marginRight: "2%",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },

  link: {
    textDecoration: "none",
    marginRight: "2%",
    marginLeft: "2%",
  },
  link1: {
    textDecoration: "none",
  },
  cartLogo: {
    [theme.breakpoints.down("sm")]: {
      width: 42,
      height: 40,
    },
  },
  whiteBTNTxt: {
    fontFamily: ROCKWELL_REG,
    fontSize: 24,
  },
  toolBarWeb: {
    paddingTop: 10,
    alignContent: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  toolBarMobile: {
    justifyContent: "space-between",
    alignContent: "center",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  loginBtn: {
    paddingLeft: 25,
    paddingRight: 25,
    fontFamily: ROCKWELL_REG,
    fontWeight: "bold",
    color: theme.palette.secondary.main,
    [theme.breakpoints.up("md")]: {
      fontSize: 20,
    },
  },
  cartCount: {
    borderRadius: 30,
    backgroundColor: theme.palette.primary.main,
    position: "fixed",
    width: 20,
    height: 20,
    top: "3%",
    right: "8%",
    justifyContent: "center",
    alignContent: "center",
    [theme.breakpoints.down("sm")]: {
      top: "1%",

      fontSize: 20,
      right: 13,
    },
  },
}));
const Header = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem button key={"About"}>
          <ListItemText>
            <NavLink to="/" className={classes.link1}>
              <Typography color="primary" className={classes.whiteBTNTxt}>
                About
              </Typography>
            </NavLink>
          </ListItemText>
        </ListItem>

        <ListItem button key={"Blog"}>
          <ListItemText>
            <NavLink to="/blogs" className={classes.link1}>
              <Typography color="primary" className={classes.whiteBTNTxt}>
                Blog
              </Typography>
            </NavLink>
          </ListItemText>
        </ListItem>
      </List>
    </div>
  );

  return (
    <AppBar
      position="fixed"
      className={classes.root}
      color="transparent"
      ref={ref}
    >
      <Toolbar className={classes.toolBarWeb}>
        <a href="/" /* className={classes.homeLink} */>
          <img alt="Adyll" src={LOGO} />
        </a>
        <div className={classes.grow} />

        <NavLink to="/" className={classes.link}>
          <Typography color="primary" className={classes.whiteBTNTxt}>
            About
          </Typography>
        </NavLink>

        <NavLink to="/blogs" className={classes.link}>
          <Typography color="primary" className={classes.whiteBTNTxt}>
            Blog
          </Typography>
        </NavLink>
        <NavLink to="/" className={classes.link}>
          <img alt="Cart" src={CART} className={classes.cartLogo} />
          <Grid className={classes.cartCount} container>
            <Typography color="secondary">0</Typography>
          </Grid>
        </NavLink>
      </Toolbar>
      <Toolbar className={classes.toolBarMobile}>
        {/* <img alt="Adyll" src={LeftMenuSm} onClick={toggleDrawer("left", true)}/> */}

        <React.Fragment key={"left"}>
          <img
            alt="Adyll"
            src={LeftMenuSm}
            onClick={toggleDrawer("left", true)}
          />
          <Drawer
            anchor="left"
            open={state["left"]}
            onClose={toggleDrawer("left", false)}
          >
            {list("left")}
          </Drawer>
        </React.Fragment>
        <a href="/" /* className={classes.homeLink} */>
          <img alt="Adyll" src={LOGO} className={classes.logo} />
        </a>
        <NavLink to="/" className={classes.link}>
          <img alt="Cart" src={CART} className={classes.cartLogo} />
          <Grid className={classes.cartCount} container>
            <Typography color="secondary" className={classes.count}>
              0
            </Typography>
          </Grid>
        </NavLink>
      </Toolbar>
    </AppBar>
  );
});

export default withRouter(Header);
